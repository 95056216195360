<template>
  <v-card color="surface" variant="flat" class="w-100 h-100">
    <v-container fluid v-if="showFilter" class="bg-surface">
      <v-row justify="start">
        <v-col cols="10">
          <template v-for="(value, key) in data" :key="key">
            <v-chip color="tertiary" class="ma-1" size="small" :disabled="loading" closable rounded
              @click:close="removeFilter(key)" v-if="value !== ''">
              <span class="text-caption">{{ key }}: {{ typeof value === 'object' ? value.title : value }}</span>
            </v-chip>
          </template>
        </v-col>

        <v-col cols="2" class="d-flex align-center justify-center">
          <v-btn size="x-small" rounded variant="tonal" elevation="3" icon="mdi-filter">
            <v-icon icon="mdi-filter" />
            <v-dialog v-model="dialog" transition="dialog-top-transition" activator="parent" width="auto">
              <patient-rpm-filter @severity-patient="submitSeverityPatientFilter" @close="dialog = false" />
            </v-dialog>
          </v-btn>
          <v-btn size="x-small" class="mx-4" variant="tonal" elevation="3" @click="clearFilters" v-if="hasFilters"
            rounded icon="mdi-filter-remove" />
        </v-col>
        <v-divider thickness="1" class="mb-2"></v-divider>
      </v-row>
    </v-container>
    <v-data-table-server v-model:items-per-page="itemsPerPage" :headers="headers" :items-length="totalItems"
      :items="severPatientLists" :loading="loading" :search="search" item-value="name" density="compact"
      @update:options="paginationChanged" @click:row="onRowClick" @update:sort-by="updateSortBy" color="primary"
      :items-per-page-options="itemsPerPageOptions">
      <template v-slot:item.lastReadingTime="{ item }">
        <v-tooltip location="bottom">
          <template v-slot:activator="{ props }">
            <span v-bind="props">{{ item.lastReadingTime }}</span>
          </template>
          <span>{{ item.lastReadingTooltip }}</span>
        </v-tooltip>
      </template>
    </v-data-table-server>
  </v-card>
</template>
<script setup lang="ts">
import { ISeverityFilter, ISortData, PatientInsight, PatientInsightDetail, PatientProgramVitalsFilter, PatientProgramVitalsSortBy, SeverityPatientFilter } from '@/interfaces/patientInsights.interface';
import { ref, defineProps, watch, computed, onMounted, defineEmits } from 'vue';
import PatientRpmFilter from '../filter/PatientRpmFilter.vue';
import { apolloClient } from '@/plugins/graphqlclient';
import { PATIENT_LISTS_BY_VITAL_SEVERITY } from '@/graphql/resources/patientInshights/patientProgramInsightsRepository';
import { daysFromNow } from '../../../helpers/date.helpers'
import { patientProgramInsightsEventBusKey } from '@/events/bus-keys/patient-program-insights-event.bus-key';
import { useEventBus } from '@vueuse/core';
import { useQueryClient } from '@tanstack/vue-query';
import { PatientProgramVitalsSortKey, SortOrder, Vitals, VitalsSeverity } from '../../../enums/patientInsights.enum';
import { defaultTo, first, get, map, maxBy } from 'lodash';
import moment from 'moment';
import { useTimeAgo } from '@vueuse/core';


const props = defineProps({
  patientVitalType: {
    type: String,
    required: true,
  },
  showFilter: {
    type: Boolean,
    default: false
  }
});

const itemsPerPageOptions = [5, 10, 25, 50, 100, -1];
const itemsPerPage = ref(props.showFilter ? import.meta.env.VITE_TOTAL_VISIBLE_PAGE_NO : import.meta.env.VITE_DASHBOARD_TOTAL_VISIBLE_PAGE_NO);
const totalItems = ref(0);
const loading = ref(false);
const search = ref('');
const severPatientLists = ref([] as PatientInsightDetail[]);
const dialog = ref(false);
const severityType = ref();
const data = ref({} as ISeverityFilter)
const isSorted = ref(false);
const sortingData = ref();
const patientProgramInsightsEvent = useEventBus(patientProgramInsightsEventBusKey);
const queryClient = useQueryClient();

type DataTableHeader = {
  key: string;
  value?: string;
  title: string;
  colspan?: number;
  rowspan?: number;
  fixed?: boolean;
  align?: 'start' | 'end';
  width?: number;
  minWidth?: string;
  maxWidth?: string;
  sortable?: boolean;
  sort?: (a: any, b: any) => number;
};
const headers: DataTableHeader[] = [
  {
    title: 'First Name',
    align: 'start',
    key: 'firstName',
  },
  { title: 'Last Name', key: 'lastName', align: 'start' },
  { title: 'Provider', key: 'providerName', align: 'start' },
  { title: 'Systolic', key: 'systolic', align: 'start' },
  { title: 'Diastolic', key: 'diastolic', align: 'start' },
  { title: 'Last Reading (Time)', key: 'lastReadingTime', align: 'start' },
];

const emits = defineEmits(['item-selected'])
const page = ref(1);
const hasFilters = computed(() => {
  return Object.keys(data.value).length > 0 && (Object.values(data.value).some(value => value));
});

const loadSeverPatient = () => {
  loading.value = true;
  const offset = (page.value - 1) * itemsPerPage.value;
  const limit = itemsPerPage.value;
  if (severityType.value) {
    getSeverityPatientList(limit, offset, isSorted.value ? sortingData.value : undefined);
  }
};

const paginationChanged = ({ page: updatedPage, itemsPerPage: updatedItemsPerPage }: { page: number; itemsPerPage: number }) => {
  page.value = updatedPage;
  itemsPerPage.value = updatedItemsPerPage;
  if (severityType.value) {
    loadSeverPatient();
  }
};

const submitSeverityPatientFilter = async (patientFiltrationData: SeverityPatientFilter) => {
  dialog.value = false;
  data.value = {
    patientName: patientFiltrationData.patientName,
    provider: patientFiltrationData.provider,
  };

  const offset = (page.value - 1) * itemsPerPage.value;
  const limit = itemsPerPage.value;

  await getSeverityPatientList(limit, offset, isSorted.value ? sortingData.value : undefined);
}

const removeFilter = (keyToRemove: string) => {
  data.value = Object.fromEntries(
    Object.entries(data.value).filter(([key]) => key !== keyToRemove)
  );
  loadSeverPatient();
}

const clearFilters = () => {
  data.value = {};
  itemsPerPage.value = 10;
  loadSeverPatient();
}

const updateSortBy = (sortedData: ISortData[]) => {

  if (sortedData && sortedData.length > 0) {
    isSorted.value = true;
    const [{ key, order }] = sortedData;

    const keyMapping: Record<string, PatientProgramVitalsSortKey> = {
      firstName: PatientProgramVitalsSortKey.patientFirstName,
      lastName: PatientProgramVitalsSortKey.patientLastName,
      providerName: PatientProgramVitalsSortKey.providerName,
      systolic: PatientProgramVitalsSortKey.systolic,
      diastolic: PatientProgramVitalsSortKey.diastolic,
    };

    const sortKey = defaultTo(
      keyMapping[key],
      PatientProgramVitalsSortKey.lastSync
    );

    const sortOrder = order.toUpperCase() as SortOrder;

    const sortBy: PatientProgramVitalsSortBy = {
      sortKey,
      sortOrder,
    };

    const offset = (page.value - 1) * itemsPerPage.value;
    const limit = itemsPerPage.value;

    sortingData.value = sortBy;

    getSeverityPatientList(limit, offset, sortBy);
  }
  else {
    isSorted.value = false;
  }
};

const getSeverityPatientList = async (limit: number, offset: number, sortBy?: PatientProgramVitalsSortBy) => {
  loading.value = true;

  const filter = {
    vital: Vitals.BP,
    vitalFilter: {
      severity: severityType.value
    },
    patientName: data.value.patientName,
    provider: data.value.provider?.value,
  } as PatientProgramVitalsFilter;

  const response = await apolloClient.query({
    query: PATIENT_LISTS_BY_VITAL_SEVERITY,
    variables: {
      limit: limit,
      offset: offset,
      filter: filter,
      sort: sortBy
    },
    fetchPolicy: 'network-only'
  });
  const severityPatientListData = response.data.patientListByVitalsSeverity;
  totalItems.value = severityPatientListData.count;
  severPatientLists.value = map(severityPatientListData.results, (patient) => {
    const reading = first(patient.patientReadings);
    const lastReadingTime = get(reading, 'collectedTimestamp');
    const systolic = get(reading, 'reading.formatted.systolic')
    const diastolic = get(reading, 'reading.formatted.diastolic');

    return {
      ...patient,
      lastReadingTime: lastReadingTime ? useTimeAgo(lastReadingTime).value : 'N/A',
      systolic: systolic ? systolic : 'N/A',
      diastolic: diastolic ? diastolic : 'N/A',
      lastReadingTooltip: lastReadingTime ? moment(lastReadingTime).format('MM/DD/YYYY, h:mm:ss A') : 'No data available',
    };

  });
  loading.value = false;
}

const onRowClick = (
  _meta: unknown,
  event: { item: { raw: PatientInsight } }
) => {
  const data = event.item;
  emits('item-selected', data);
};

watch(() => props.patientVitalType, () => {
  severityType.value = props.patientVitalType;
  loadSeverPatient();
})

onMounted(() => {
  if (props.patientVitalType) {
    severityType.value = props.patientVitalType;
    loadSeverPatient();
  }

  patientProgramInsightsEvent.on(() => {
    loadSeverPatient();
  })
})

</script>
