<template>
  <div class="w-100 p-10 flex justify-center align-center">
    <v-container fluid class="text-center">
      <v-row>
        <v-col cols="12" class="flex justify-center">
          <v-card class="program-card" flat>
            <v-list>
              <v-list-item v-for="patientProgram in patientPrograms">
                <v-checkbox v-model="selectedPrograms" :label="patientProgram.programName" color="primary"
                  class="text-h6" :disabled="canModifyProgramSelection(patientProgram)  || !patientProfile.patientInfo?.isActive" :value="patientProgram"
                  @update:model-value="handleProgramSelection(patientProgram)" hide-details></v-checkbox>
                <template v-slot:append>
                  <div v-if="patientProgram.isDefault" class="text-caption">This program is consented by
                    default
                  </div>
                  <template v-else>
                    <v-btn v-if="hasConsentStatus(patientProgram, EConsentStatus.SIGNED) && patientProgram.isSubscribed"
                      rounded="false" elevation="3" class="mx-2" variant="tonal"
                      @click="handleConsentDownload(patientProgram.consent as IEConsent)">Download
                      Consent</v-btn>
                    <v-btn v-if="hasConsentStatus(patientProgram, EConsentStatus.SIGNED) && patientProgram.isSubscribed"
                      rounded="false" elevation="3" class="mx-2" variant="tonal"
                      @click="triggerResetConsent({ eConsentTemplateId: (patientProgram.consent as IEConsent).eConsentTemplateId })">Reset
                      Consent</v-btn>
                    <v-btn
                      v-else-if="hasConsentStatus(patientProgram, EConsentStatus.PENDING) && patientProgram.isSubscribed"
                      rounded="false" elevation="3" class="mx-2"
                      @click="loadConsentTemplate(patientProgram as IPatientProgram)">Get
                      Consent</v-btn>
                  </template>
                </template>
              </v-list-item>
            </v-list>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog v-model="showConfirmationDialog" persistent width="500px">
      <v-card min-height="200px">
        <v-card-title class="font-weight-bold bg-surface text-onSurfaceVar pl-5">Confirm</v-card-title>
        <v-card-text class="text-subtitle-1 word-wrap">
          {{ isProgramSubscribed ? SUBSCRIBE_MESSAGE : UNSUBSCRIBE_MESSAGE }}
          <v-list class="text-subtitle-1 font-weight-bold text-primary">
            <v-list-item :key="idx" class="pa-0" v-for="(program, idx) in curSelectedPrograms">
              {{ `${idx + 1}. ${program.programName}` }}
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-card-actions class="px-3 py-5">
          <v-row justify="end">
            <v-col cols="12" class="text-right">
              <v-btn color="primary" class="text-white mx-2" variant="tonal" elevation="3" rounded="false"
                @click="resetSelectedPrograms()">
                Cancel
              </v-btn>
              <v-btn color="primary" class="text-white mx-2" variant="flat" elevation="3" rounded="false"
                @click="handleProgramSubscription">
                {{ isProgramSubscribed ? 'Subscribe' : 'Unsubscribe' }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showResetConsentConfirmationDialog" persistent width="500px">
      <v-card min-height="200px">
        <v-card-title class="font-weight-bold bg-surface text-onSurfaceVar pl-5">Confirm</v-card-title>
        <v-card-text class="text-subtitle-1 word-wrap">
          {{ RESET_CONSENT_MESSAGE }}
        </v-card-text>
        <v-card-actions class="px-3 py-5">
          <v-row justify="end">
            <v-col cols="12" class="text-right">
              <v-btn color="primary" class="text-white mx-2" variant="tonal" elevation="3" rounded="false"
                @click="showResetConsentConfirmationDialog = false">
                Cancel
              </v-btn>
              <v-btn color="primary" class="text-white mx-2" variant="flat" elevation="3" rounded="false"
                @click="resetPatientProgramEConsent">
                Reset
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog fullscreen class="template-viewer-dialog" persistent v-model="showTemplate">
      <e-consent-template-viewer @close-template-viewer="closeTemplateViewer()" :patient-profile="patientProfile"
        :econsent-template-id="consentingTemplateId" :template-params="templateParams"
        :get-consent-upload-url="getConsentUploadUrl" @reload-patient-program-consents="handleConsentSigned()"
        :sign-patient-program-consent="signPatientProgramConsent" :selected-program-id="(selectedProgramId as string)"
        :data-loader="consentTemplateLoader"></e-consent-template-viewer>
    </v-dialog>
    <v-snackbar color="error" class="text-white" v-model="showEconsentOperationFailedErr" location="top right">
      {{ errorMessage }}
      <template v-slot:actions>
        <v-icon class="ml-3" @click="showEconsentOperationFailedErr = false">mdi-close</v-icon>
      </template>
    </v-snackbar>
    <Loader :overlay="loading" />
  </div>
</template>
<script setup lang="ts">
import { IEConsent, IPatientProgram, IProgramConsentResponse } from '@/interfaces/econsent.interface';
import { IPatientProfileProps } from '@/interfaces/patient.interface';
import { differenceBy, filter, find, first, isEmpty, map } from 'lodash';
import { PropType, onMounted, ref } from 'vue';
import EConsentTemplateViewer from './EConsentTemplateViewer.vue';
import { IEConsentTemplate, TemplateParams } from '@/interfaces/econsent-template.interface';
import Loader from "../common/Loader.vue";
import { EConsentAcknowledgement, EConsentStatus } from '../../enums/patient-program.enum'
import { useEventBus } from '@vueuse/core';
import { billingProgramSubscribeEventBusKey, subscriptionEventBusKey } from '@/events/bus-keys/billing-program-subscription-event.bus-keys';
import useConsentDownload from '@/composables/eConsentUtility';
import moment from 'moment';
import { SubscriptionActionEventBusKey } from '@/events/bus-keys/display-program-subscription-even-bus';


const { dataLoader, patientProfile, consentTemplateLoader, subscribePrograms, signPatientProgramConsent, getConsentDownloadUrl, unSubscribeProgram } = defineProps({
  patientProfile: {
    type: Object as PropType<IPatientProfileProps>,
    required: true,
  },
  dataLoader: {
    type: Function as PropType<(patientId: string) => Promise<IProgramConsentResponse[]>>,
    required: true,
  },
  subscribePrograms: {
    type: Function as PropType<(patientId: string, programIds: string[]) => Promise<IPatientProgram[]>>,
    required: true,
  },
  consentTemplateLoader: {
    type: Function as PropType<(econsentTemplateId: string, templateParamsInput: TemplateParams) => Promise<IEConsentTemplate>>,
    required: true,
  },
  getConsentUploadUrl: {
    type: Function as PropType<(
      patientId: string,
      fileName: string) => Promise<string>>,
    required: true,
  },
  getConsentDownloadUrl: {
    type: Function as PropType<(
      eConsentId: string) => Promise<string>>,
    required: true,
  },
  signPatientProgramConsent: {
    type: Function as PropType<(
      patientId: string,
      eConsentTemplateId: string, eConsentFileName: string, selectedProgramId: string, acknowledgment: EConsentAcknowledgement) => Promise<IPatientProgram[]>>,
    required: true,
  },
  unSubscribeProgram: {
    type: Function as PropType<(patientId: string, programId: string) => Promise<void>>,
    required: true,
  }
})

const patientPrograms = ref([] as IPatientProgram[]);
const selectedPrograms = ref([] as IPatientProgram[]);
const showConfirmationDialog = ref(false);
const curSelectedPrograms = ref([] as IPatientProgram[]);
const isProgramSubscribed = ref(false);
const UNSUBSCRIBE_MESSAGE = `Are you sure you want to unsubscribe the ${patientProfile.patientInfo?.firstName} ${patientProfile.patientInfo?.lastName} from the following program(s)?`;
const SUBSCRIBE_MESSAGE = `Are you sure you want to subscribe the patient ${patientProfile.patientInfo?.firstName} ${patientProfile.patientInfo?.lastName} to the following program(s)?`;
const RESET_CONSENT_MESSAGE = `Are you sure you want to reset the consent?`;
const consentingTemplateId = ref('');
const showTemplate = ref(false);
const { patientInfo } = patientProfile
const templateParams = ref({
  patientName: `${patientInfo?.firstName} ${patientInfo?.lastName}`,
  date: moment().format('MM/DD/YYYY'),
  dob: patientInfo?.dob,
} as TemplateParams);
const loading = ref(false);
const billingProgramSubscribeEventBus = useEventBus(billingProgramSubscribeEventBusKey);
const SubscriptionActionEventBus = useEventBus(SubscriptionActionEventBusKey);
const { errorMessage, showEconsentOperationFailedErr, handleConsentDownload, handleResetConsent } = useConsentDownload(loading);
const selectedProgramId = ref(null as null | string);
const showResetConsentConfirmationDialog = ref(false);
let selectedPatientIdToReset = null as null | string;
let selectedTemplateToReset = null as null | string;

const hasConsentStatus = (patientProgram: IPatientProgram, consentStatus: EConsentStatus) => {
  return (
    patientProgram?.patientProgramId &&
    patientProgram?.consent?.status === consentStatus.toString()
  ) ?? false;
}

const loadPatientPrograms = async () => {
  loading.value = true;
  patientPrograms.value = (await dataLoader(patientProfile.patientId)).map(
    (programConsent) => programConsent as IPatientProgram
  );
  selectedPrograms.value = filter(patientPrograms.value, (program) => !!program.patientProgramId && program.isSubscribed);
  loading.value = false;
}

const triggerResetConsent = ({ eConsentTemplateId }: { eConsentTemplateId: string }) => {
  showResetConsentConfirmationDialog.value = true;
  selectedPatientIdToReset = patientProfile.patientId;
  selectedTemplateToReset = eConsentTemplateId;
}

const resetPatientProgramEConsent = async () => {
  showResetConsentConfirmationDialog.value = false;
  await handleResetConsent({ patientId: selectedPatientIdToReset as string, eConsentTemplateId: selectedTemplateToReset as string });
  await loadPatientPrograms();
  SubscriptionActionEventBus.emit('unSubscription')
  billingProgramSubscribeEventBus.emit('isSubscriptionEmit');
}

const handleProgramSelection = (program: IPatientProgram) => {
  const isProgramSelected = !isEmpty(filter(selectedPrograms.value, (selectedProgram) => selectedProgram.programId === program.programId));
  curSelectedPrograms.value = [];
  if (isProgramSelected) {
    curSelectedPrograms.value.push(program);
    handleParentProgramSelection(program.parentProgramId, selectedPrograms.value, curSelectedPrograms.value);
    showConfirmationDialog.value = true;
    isProgramSubscribed.value = true;
  }
  else {
    curSelectedPrograms.value.push(program);
    showConfirmationDialog.value = true;
    isProgramSubscribed.value = false;
  }
};

const handleParentProgramSelection = (parentProgramId: string | undefined, selectedPrograms: IPatientProgram[], curSelectedPrograms: IPatientProgram[]) => {
  const parentProgram = find(patientPrograms.value, { programId: parentProgramId });
  if (parentProgram) {
    const isParentProgramSelected = !isEmpty(filter(selectedPrograms, (selectedProgram) => selectedProgram.programId === parentProgram.programId));
    if (!isParentProgramSelected) {
      selectedPrograms.push(parentProgram);
      curSelectedPrograms.push(parentProgram);
    }
  }
}

const resetSelectedPrograms = () => {
  if (isProgramSubscribed.value) {
    selectedPrograms.value = differenceBy(selectedPrograms.value, curSelectedPrograms.value, 'programId');
  } else {
    selectedPrograms.value.push(...curSelectedPrograms.value);
  }
  showConfirmationDialog.value = false;
}

const handleProgramSubscription = async () => {
  loading.value = true;
  const programIds = map(curSelectedPrograms.value, 'programId');
  if (!isEmpty(programIds) && first(programIds)) {
    isProgramSubscribed.value
      ? await subscribePrograms(patientProfile.patientId, programIds) : await unSubscribeProgram(patientProfile.patientId, first(programIds) as string);

    await loadPatientPrograms();
    curSelectedPrograms.value = [];
    loading.value = false;
    showConfirmationDialog.value = false;
    SubscriptionActionEventBus.emit('Subscription');
    billingProgramSubscribeEventBus.emit('isSubscriptionEmit')
  }
}

const canModifyProgramSelection = (patientProgram: IPatientProgram) => {
  const childPrograms = selectedPrograms.value.filter(program => { return program.parentProgramId === patientProgram.programId });
  return patientProgram.isDefault || !isEmpty(childPrograms);
}

const loadConsentTemplate = (eConsent: IPatientProgram) => {
  selectedProgramId.value = eConsent.programId;
  consentingTemplateId.value = eConsent.consent?.eConsentTemplateId as string;
  showTemplate.value = true;
}

const closeTemplateViewer = () => {
  showTemplate.value = false;
}

const handleConsentSigned = () => {
  showTemplate.value = false;
  loadPatientPrograms();
  SubscriptionActionEventBus.emit('Subscription')
  billingProgramSubscribeEventBus.emit('reTriggerEvent');
}

onMounted(async () => {
  await loadPatientPrograms();
  useEventBus(subscriptionEventBusKey).on(() => {
    loadPatientPrograms();
    billingProgramSubscribeEventBus.emit('reTriggerEvent');
  })
})

</script>

<style scoped lang="scss">
.program-card {
  max-width: 1000px;
  width: 100%;
  margin: auto;
}

.template-viewer-dialog {
  overflow-y: scroll;
}
</style>
