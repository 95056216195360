import { PATIENT_LINKED_USERS, UPDATE_USER_PATIENT_LINK_STATUS } from "@/graphql/resources/PE/UserPatientLink";
import { IUpdateUserPatientLinkStatusInput } from "@/interfaces/PE/usePatientLink.interface";
import { apolloClient } from "@/plugins/graphqlclient";

export const getPatientLinkedUsers = async ({
  patientId,
  isActive,
  limit,
  offset,
}: {
  patientId?: string;
  limit?: number;
  offset?: number;
  isActive?: boolean;
}) => {
  const response = await apolloClient.query({
    query: PATIENT_LINKED_USERS,
    variables: { patientId, isActive, limit, offset },
    fetchPolicy: "network-only",
  });

  return  response.data.pePatientLinkedUsers;
}

export const updateUserPatientLinkStatus = async (updateUserPatientLinkStatusInput: IUpdateUserPatientLinkStatusInput) => {
  const response = await apolloClient.mutate({
    mutation: UPDATE_USER_PATIENT_LINK_STATUS,
    variables: {
      updateUserPatientLinkStatusInput
    }
  });

  return response.data;
}
