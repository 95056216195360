export enum VitalsSeverity {
  HIGH = "HIGH",
  MODERATE = "MODERATE",
  NORMAL = "NORMAL",
  LATE = "LATE",
  VERY_LATE = "VERY_LATE",
  NOT_REVIEWED = "NOT_REVIEWED",
  COMPLIANT = "COMPLIANT",
}

export enum VitalsReportingComplianceLevel {
  COMPLIANT = "COMPLIANT",
  LATE = "LATE",
  VERY_LATE = "VERY_LATE",
}

export enum Vitals {
  BP = "BP",
  WEIGHT = "WEIGHT",
  BPM = "BPM",
  SPO2 = "SPO2",
  RESP_RATE = "RESP_RATE",
  BGL = "BGL",
  BODY_TEMP = "BODY_TEMP",
  ECG = "ECG",
}

export enum PatientProgramVitalsSortKey {
  patientFirstName = "patientFirstName",
  patientLastName = "patientLastName",
  lastSync = "lastSync",
  providerName = "providerName",
  daysActive = "daysActive",
  daysMissed = "daysMissed",
  daysLeft = "daysLeft",
  minutesSpent = "minutesSpent",
  systolic = 'systolic',
  diastolic = 'diastolic',
}

export enum SortOrder {
  Asc = "ASC",
  Desc = "DESC",
  Default = "Default"
}

export enum Program {
  RPM = "RPM",
}
