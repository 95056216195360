<template>
  <v-card color="surfContainerHigh" class="w-100 h-100 pa-2">
    <v-skeleton-loader v-show="loadingPatientInfo" type="avatar, table-heading" />
    <v-card-title v-show="!loadingPatientInfo" class="pa-2 d-flex bg-surface elevation-2 rounded-lg ">
      <v-avatar rounded size="75" :color="avatarColor">
        <h5 class="text-h5 font-weight-bold">{{ initials }}</h5>
      </v-avatar>
      <div class="d-flex justify-space-around w-100">
        <div class="pa-1 mx-2">
          <h6 class="text-h6 text-onSurfaceVar  font-weight-black">{{ patientName }}</h6>
          <span class="text-caption text-primary font-weight-black"> MRN:</span> <span
            class="text-subtitle-2  font-weight-black"><strong>{{
              patientMrn
            }}</strong> </span>
        </div>
        <div class="pa-1 mx-2 d-flex  flex-column align-center justify-center pb-8">
          <div>
            <span class="text-subtitle-2 font-weight-black text-primary mr-1">{{ externalIdDetail.label }}:</span><span
              class=" text-onSurfaceVar text-subtitle-1 font-weight-black">
              {{
                externalIdDetail?.value
              }}</span>
          </div>
        </div>
        <div class="pa-1 mx-2 d-flex  flex-column align-center justify-center">
          <div>
            <span class="text-subtitle-2 font-weight-black text-primary mr-1">Age </span><span
              class=" text-onSurfaceVar text-subtitle-1 font-weight-black">
              {{
                patientAge }} years</span>
          </div>
          <div>
            <span class="text-subtitle-2 font-weight-black text-primary mr-1">D.O.B</span><span
              class=" text-onSurfaceVar text-subtitle-1 font-weight-black">
              {{
                patientData?.dob }}</span>
          </div>
        </div>
        <div class="pa-1 mx-2  d-flex  flex-column align-center justify-center">
          <div>
            <span class="text-subtitle-2 font-weight-black text-primary mr-1">State </span><span
              class=" text-onSurfaceVar text-subtitle-1 font-weight-black">
              {{
                patientState }} </span>
          </div>
          <div>
            <span class="text-subtitle-2 font-weight-black text-primary mr-1">City</span><span
              class=" text-onSurfaceVar text-subtitle-1 font-weight-black">
              {{
                patientCity }}</span>
          </div>
        </div>

      </div>
    </v-card-title>
    <v-container fluid class="px-2 py-2">
      <v-row>
        <v-col cols="6" class="px-1">
          <v-skeleton-loader v-if="loadingPatientInfo" type="card, list-item-avatar-three-line" />
          <v-card v-else color="surface" class="pa-2 h-100">
            <v-card-title class="text-subtitle-1 font-weight-black text-onSurfaceVar">Contact
              Information</v-card-title>
            <v-divider thickness="2" class="my-1" />
            <v-card-text class="d-flex flex-column align-center text-center justify-space-around">
              <v-list density="compact" variant="text" bg-color="surface">
                <v-list-item class="my-2">
                  <v-list-item-title>
                    <span class="text-subtitle-2 font-weight-black text-primary"><v-icon
                        class="ma-2">mdi-cellphone</v-icon>Mobile</span>
                  </v-list-item-title>
                  <div class="d-flex justify-center">
                    {{ patientData?.mobilePhone ||
                      'N/A'
                    }}
                  </div>
                </v-list-item>
                <v-list-item class="my-2">
                  <v-list-item-title>
                    <span class=" font-weight-black text-primary"><v-icon
                        class="ma-2">mdi-email-outline</v-icon>Email</span>
                  </v-list-item-title>
                  <div class="d-flex justify-center">
                    <span class="text-body-1 font-weight-black text-onSurfaceVar">{{ patientData?.email || 'N/A'
                    }}</span>
                  </div>
                </v-list-item>
                <v-list-item class="my-2">
                  <v-list-item-title>
                    <span class="text-subtitle-1 font-weight-black text-primary"> <v-icon
                        class="ma-2">mdi-mailbox-open-outline</v-icon> Address</span>
                  </v-list-item-title>
                  <div class="d-flex justify-center">
                    <span class="text-body-1 font-weight-black text-onSurfaceVar">{{
                      patientData?.addressLine1 || 'No address line 1' }},
                      {{ patientData?.city || 'No city' }}, {{ patientData?.state || 'No state' }}, {{
                        patientData?.zip
                        || 'No zip code' }}</span>
                  </div>
                </v-list-item>
              </v-list>

            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="6" class="px-1">
          <v-card color="surface" class="pa-2 w-100 h-100">
            <v-card-title class="text-subtitle-1 font-weight-black text-onSurfaceVar">Quick Actions</v-card-title>
            <v-divider thickness="2" class="my-1" />
            <v-card-text class="d-flex  justify-space-around">
              <v-list density="compact" variant="text" bg-color="surface" class="text-center">
                <v-list-item>
                  <v-list-item-title>
                    <span class=" font-weight-black text-primary"> <v-icon class="mx-2">mdi-account-reactivate</v-icon>
                      Toggle Patient Status</span>
                  </v-list-item-title>
                  <div class="d-flex justify-center">
                    <v-switch inset :color="activeColor" :label="activeLabel" v-model="isActive"
                      @change="() => { confirmDialog = true; isDeceasedDialog = false; }"
                      :disabled="isDeceased || loadingPatientInfo || !hasAccess.viewPatientActions(viewOrgAdminRole)"
                      :loading="loadingPatientInfo" />
                  </div>
                </v-list-item>
                <v-list-item>
                  <v-list-item-title class=" font-weight-black text-primary">
                    <v-icon class="mx-2">mdi-clock-end</v-icon> <span>Mark Patient Deceased</span>
                  </v-list-item-title>
                  <div class="d-flex justify-center">
                    <v-switch inset :color="deceasedColor" label="Deceased" v-model="isDeceased" class="ml-3"
                      @change="() => { confirmDialog = true; isDeceasedDialog = true; }"
                      :disabled="isActive || loadingPatientInfo || !hasAccess.viewPatientActions(viewOrgAdminRole)"
                      :loading="loadingPatientInfo" />
                  </div>
                </v-list-item>
                <v-list-item>
                  <div
                    :style="(!compactView && $vuetify.display.lgAndUp) ? { display: 'flex', justifyContent: 'space-between', } : {}">
                    <div class="mx-5">
                      <v-list-item-title class="font-weight-black text-primary">
                        <v-icon class="mx-2">mdi-file-phone</v-icon>
                        <span>Record Patient Call Detail</span>
                      </v-list-item-title>
                      <div class="d-flex justify-center mt-4 pl-5">
                        <v-btn rounded="false" size="small" variant="elevated" :loading="loadingPatientInfo"
                          @click="lastCallEventDialog = true">
                          <v-icon size="large" class="mx-1">mdi-plus</v-icon>Add call record
                        </v-btn>
                      </div>
                    </div>
                    <div class="mx-5" :class="(!compactView && $vuetify.display.lgAndUp) ? '' : 'mt-5'">
                      <v-list-item-title class="font-weight-black text-primary">
                        <v-icon class="mx-2">mdi-timetable</v-icon>
                        <span>Record Patient Last Appointment</span>
                      </v-list-item-title>
                      <div class="d-flex justify-center mt-4 pl-5">
                        <v-btn rounded="false" size="small" variant="elevated" :loading="loadingPatientInfo"
                          @click="lastAppointmentEventDialog = true">
                          <v-icon size="large" class="mx-1">mdi-plus</v-icon>Add Last Appointment
                        </v-btn>
                      </div>
                    </div>
                  </div>
                </v-list-item>
              </v-list>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog v-model="confirmDialog" persistent :width="!isActive && !isDeceasedDialog ? '550px' : '500px'">
      <v-card min-height="200px">
        <v-card-title class="font-weight-bold bg-surface pl-5">Confirm</v-card-title>
        <v-divider />
        <v-card-text class="text-h6">
          <template v-if="isDeceased">
            Are you sure you want to change the status of the patient
          </template>
          <template v-else-if="!isActive && !isDeceased && isDeceasedDialog">
            Are you sure you want to change the deceased status of the patient
          </template>
          <template v-else-if="!isActive && !isDeceasedDialog">
            Are you sure you want to deactivate the patient
          </template>
          <template v-else>
            Are you sure you want to activate the patient
          </template>
          <v-label class="text-h6 font-weight-bold text-primary">
            {{ patientData?.firstName }} {{ patientData?.lastName }}
          </v-label>
          <template v-if="isDeceased">
            {{ " to deceased" }}
          </template>
          <template v-if="true">?</template>
          <div v-if="!isActive && !isDeceasedDialog" class="mt-2 d-flex align-start">
            <v-icon color="warning" class="mr-2 mt-1">mdi-alert</v-icon>
            <span class="text-body-2">
              Upon deactivation, the patient will be unsubscribed from all the care programs and the currently assigned
              device(s) will be unassigned.
            </span>
          </div>
        </v-card-text>
        <v-card-actions class="px-3 py-5">
          <v-row justify="end">
            <v-col sm="12" md="3" class="text-center">
              <v-btn color="primary" class="text-white" density="comfortable" @click="cancelStatusUpdateEvent"
                variant="tonal" block rounded="false">
                Cancel
              </v-btn>
            </v-col>
            <v-col sm="12" md="3" class="text-center">
              <v-btn color="primary" class="text-white" variant="flat" type="submit" block density="comfortable"
                rounded="false" @click="updatePatientStatus">
                Continue
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="lastCallEventDialog" width="500px">
      <patient-last-call-event-form @close="lastCallEventDialog = false"
        @save-call-event="recordPatientLastCallEvent" />
    </v-dialog>
    <v-dialog v-model="lastAppointmentEventDialog" width="500px">
      <patient-last-appointment-form @close="lastAppointmentEventDialog = false"
        @save-appointment-event="recordPatientLastAppointmentEvent" />
    </v-dialog>
    <v-snackbar v-model="showPatientEventSuccessSnackbar" color="success" location="top right">{{
      patientEventSuccessMessage }}
      <template v-slot:actions>
        <v-icon class="ml-3" @click="showPatientEventSuccessSnackbar = false">mdi-close</v-icon>
      </template>
    </v-snackbar>
    <v-snackbar v-model="showPatientEventErrorSnackbar" color="error" location="top right">{{ patientEventErrorMessage
    }}
      <template v-slot:actions>
        <v-icon class="ml-3" @click="showPatientEventErrorSnackbar = false">mdi-close</v-icon>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script setup lang="ts">
import { onMounted, PropType, ref, computed, watch } from 'vue'
import {
  IPatient,
  IPatientInfoExtended,
  IUpdatePatientInput,
  IPatientProfileProps,
} from '@/interfaces/patient.interface'
import { Gender, GenderLabel } from '@/enums/genderType';
import { find, forEach } from 'lodash';
import { patientActiveStatusEventBusKey, patientDetailsEventBusKey } from '@/events/bus-keys/patient-active-event.bus-keys';
import { useEventBus } from '@vueuse/core';
import hasAccess, {
  viewOrgAdminRole
} from "../../../composables/roleAccess";
import { age } from '@/helpers/date.helpers';
import { EXTERNAL_IDENTITIES, EXTERNAL_IDENTITY_LABELS } from '@/enums/patient.enum';
import PatientLastCallEventForm from './PatientLastCallEventForm.vue'
import PatientLastAppointmentForm from './PatientLastAppointmentForm.vue';
import { IPatientEvent, IPatientEventInput, IPatientAppointmentData, PatientCallEventData } from '@/interfaces/patient-event.interface';
import { PatientEventName } from '@/enums/patient-event.enum';
import { updatePatientActiveStatus, updatePatientProgramInsightsStatus } from '@/services/patient-program-insight.service'


const demographicFields = ref([
  { key: 'firstName', label: 'First Name', value: '' },
  { key: 'lastName', label: 'Last Name', value: '' },
  { key: 'dob', label: 'Date of Birth', value: '' },
  { key: 'gender', label: 'Gender', value: '' },
  { key: 'addressLine1', label: 'Address Line 1', value: '' },
  { key: 'city', label: 'City', value: '' },
  { key: 'state', label: 'State', value: '' },
  { key: 'zip', label: 'Zip', value: '' },
  { key: 'mobilePhone', label: 'Mobile Number', value: '' },
  { key: 'email', label: 'Email', value: '' },
  { key: 'mrn', label: 'MRN', value: '' },
])
const isActive = ref<boolean>(true)
const isDeceased = ref<boolean>(false)
const activeColor = 'success'
const deceasedColor = 'error'
const inactiveColor = 'warning'
const loadingPatientInfo = ref(false);
const patientStatusChangeEventBus = useEventBus(patientActiveStatusEventBusKey);
const patientDetailUpdatedEventBus = useEventBus(patientDetailsEventBusKey);
const confirmDialog = ref(false);
const isDeceasedDialog = ref(false);
const externalIdDetail = ref({
  label: "",
  value: "",
});
const lastCallEventDialog = ref(false);
const lastAppointmentEventDialog = ref(false);
const showPatientEventSuccessSnackbar = ref(false);
const showPatientEventErrorSnackbar = ref(false);
const patientEventSuccessMessage = ref('');
const patientEventErrorMessage = ref('');

const props = defineProps({
  patientProfile: {
    type: Object as PropType<IPatientProfileProps>,
    required: true,
  },

  getPatientByPatientId: {
    type: Function as PropType<(patientIds: string[]) => Promise<IPatientInfoExtended>>,
    required: true,
  },

  patientStatusChange: {
    type: Function as PropType<(patientInput: IUpdatePatientInput) => Promise<IPatient>>,
    required: true,
  },

  savePatientEvent: {
    type: Function as PropType<(input: IPatientEventInput) => Promise<IPatientEvent>>,
    required: false,
  },

  compactView: {
    type: Boolean,
    required: false,
    default: false
  },
});

const updatedPatientStatusState = ref<IUpdatePatientInput>({
  patientId: props.patientProfile.patientId,
  isActive: props.patientProfile.patientInfo?.isActive,
  isDeceased: props.patientProfile.patientInfo?.isDeceased
});

const { getPatientByPatientId, patientProfile, patientStatusChange, savePatientEvent } = props;

const patientData = ref<IPatientInfoExtended | null>(null);


const fetchData = async (patientId?: string) => {
  loadingPatientInfo.value = true;
  patientData.value = await getPatientByPatientId([patientId ? patientId : patientProfile.patientId]);
  const externalIdData = find(patientData.value?.externalIds, { 'key': EXTERNAL_IDENTITIES.EMR_ID });
  externalIdDetail.value = {
    label: externalIdData?.label || EXTERNAL_IDENTITY_LABELS.EMR_ID,
    value: externalIdData?.value || 'N/A',
  }

  forEach(demographicFields.value, (field) => {
    if (patientData.value) {
      field.value = (patientData.value as any)[field.key] || 'N/A';
      isActive.value = patientData.value?.isActive ?? false;
      isDeceased.value = patientData.value?.isDeceased ?? false;
    } else {
      field.value = 'N/A';
    }

    if (field.key === 'gender') {
      field.value = GenderLabel[patientData.value?.gender as Gender] || 'N/A';
    }
  });

  loadingPatientInfo.value = false;
}


const activeLabel = computed(() => isActive.value ? 'Active' : 'Inactive');

const avatarColor = computed(() => {
  const deceased = isDeceased.value
  const active = isActive.value

  if (active) {
    return activeColor
  } else if (deceased) {
    return deceasedColor
  } else {
    return inactiveColor
  }
})

const patientName = computed(() => {
  if (patientData.value) {
    return `${patientData.value.firstName} ${patientData.value.lastName}`;
  }
  return '';
});

const patientState = computed(() => {
  if (patientData.value?.state) {
    return `${patientData.value.state}`;
  }
  return 'N/A';
});

const patientCity = computed(() => {
  if (patientData.value?.city) {
    return `${patientData.value.city}`;
  }
  return 'N/A';
});

const patientEmail = computed(() => {
  if (patientData.value) {
    return patientData.value.email;
  }
  return '';
});

const patientAge = computed(() => {
  if (patientData.value) {
    return age(patientData.value.dob);
  }
  return '';
});

const patientMrn = computed(() => {
  if (patientData.value) {
    return patientData.value.mrn;
  }
  return '';
});

const initials = computed(() => {
  if (patientData.value) {
    const firstName = patientData.value.firstName || ''
    const lastName = patientData.value.lastName || ''
    const firstInitial = firstName.charAt(0).toUpperCase()
    const lastInitial = lastName.charAt(0).toUpperCase()

    return firstInitial + lastInitial
  }

  return ''
});

const cancelStatusUpdateEvent = () => {
  confirmDialog.value = false;
  (isDeceasedDialog.value) ? (isDeceased.value = !isDeceased.value) : (isActive.value = !isActive.value);
};


const updatePatientStatus = async () => {
  confirmDialog.value = false;
  loadingPatientInfo.value = true;

  const patientData = {
    patientId: patientProfile.patientId,
    isActive: isActive.value,
    isDeceased: isDeceased.value,
  } as IUpdatePatientInput;

  if (!isActive.value) {
    updatePatientProgramInsightsStatus(patientProfile.patientId, isActive.value)
  }

  updatePatientActiveStatus({
    patientId: patientProfile.patientId,
    isActive: isActive.value,
    isDeceased: isDeceased.value
  })
    .then(() => {
      const { isActive: patientIsActive, isDeceased: patientIsDeceased } = patientData;

      patientEventSuccessMessage.value = !isDeceased.value && updatedPatientStatusState.value.isDeceased
        ? 'Patient deceased status changed successfully'
        : patientIsDeceased
          ? 'Patient marked deceased successfully'
          : patientIsActive
            ? 'Patient activated successfully'
            : 'Patient deactivated successfully';

      showPatientEventSuccessSnackbar.value = true;
      isActive.value = !!patientIsActive;
      isDeceased.value = !!patientIsDeceased;
      patientStatusChangeEventBus.emit(avatarColor.value);

      updatedPatientStatusState.value = { ...updatedPatientStatusState.value, isActive: isActive.value, isDeceased: isDeceased.value };

    })
    .catch((error) => {
      console.error(error);
    })
    .finally(() => {
      loadingPatientInfo.value = false;
    });
};

const recordPatientLastCallEvent = async (eventData: PatientCallEventData) => {
  lastCallEventDialog.value = false;
  await recordPatientEvent({
    patientId: patientData.value?.patientId!,
    eventName: PatientEventName.phone_call,
    eventTimeStamp: eventData.callAt,
    eventData: eventData,
  })
  showPatientEventSuccessSnackbar.value = true;
  patientEventSuccessMessage.value = 'Patient call record added successfully';
}

const recordPatientLastAppointmentEvent = async (eventData: IPatientAppointmentData) => {
  lastAppointmentEventDialog.value = false;
  await recordPatientEvent({
    patientId: patientData.value?.patientId!,
    eventName: PatientEventName.appointment,
    eventTimeStamp: eventData.appointmentDate,
    eventData: eventData,
  })
  showPatientEventSuccessSnackbar.value = true;
  patientEventSuccessMessage.value = 'Patient appointment record added successfully';
}

const recordPatientEvent = async (patientEventData: IPatientEventInput) => {
  try {
    loadingPatientInfo.value = true;
    const savedPatientLastCallEvent = await savePatientEvent!({
      ...patientEventData
    });

    loadingPatientInfo.value = false;

    return savedPatientLastCallEvent;
  }
  catch (error) {
    loadingPatientInfo.value = false;
    const { message } = error as Error;
    showPatientEventErrorSnackbar.value = true;
    patientEventErrorMessage.value = message;
  }
}

watch(() => props.patientProfile, (newValue) => {
  if (newValue)
    fetchData(newValue.patientId);
}, { deep: true, immediate: true })


onMounted(async () => {
  patientStatusChangeEventBus.on(() => {
    fetchData();
  });
  patientDetailUpdatedEventBus.on(() => {
    fetchData();
  })
});
</script>

<script lang="ts">
export default { name: 'PatientDemographics' }
</script>
