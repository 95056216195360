<template>
  <v-card class="paient-vitals-card pa-2" variant="flat" color="surfContainer">
    <v-card-title class="d-flex align-center text-primary bg-surface rounded-lg">
      <div class="d-flex align-center items-center text-primary">
        <v-icon size="large">mdi-devices</v-icon>
        <div class="d-flex flex-wrap">
          <span class="ml-4">Devices</span>
          <span class="v-card-subtitle flex-1-1-100">Manage patient devices </span>
        </div>
      </div>
      <v-spacer />
      <v-dialog v-model="dialog" persistent width="1024px">
        <template v-slot:activator="{ props }">
          <div class="d-flex justify-end">
            <v-btn v-if="hasAccess.viewOrgInventory(viewOrgInventoryRoles)" color="primary" rounded="false"
              @click="openShippingDialog" variant="tonal" elevation="3" class="mx-2"
              :disabled="!patientProfile.patientInfo?.isActive">
              Add Device
            </v-btn>
            <v-btn color="primary" v-bind="props" @click="loadPatientPrograms" rounded="false" class="mx-2"
              elevation="3" :loading="loading" :disabled="!patientProfile.patientInfo?.isActive">
              Assign Device
            </v-btn>
          </div>
        </template>
        <patient-device-assign-form :service-providers="serviceProviderOptions" :device-input="listSourcesInput"
          @cancel-device-assign="dialog = false" :profile-Patient-id="profilePatientId"
          @assign-device="inventoryAssignItem"
          :data-loader="getPatientProgramConsentDependencies.patientProgramConsents"
          :program-subscribed-data-loader="getDetailsDependencies.getPatientBillingConfig">

        </patient-device-assign-form>
      </v-dialog>
      <v-dialog v-model="isShippingDialogOpen" width="80%" min-height="60%" color="white">
        <v-card>
          <v-card-actions>
            <v-card-title class="text-primary">Device Onboarding</v-card-title>
            <v-spacer />
            <v-btn icon dark @click="isShippingDialogOpen = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-actions>
          <v-divider thickness="1"></v-divider>

          <CreatePatientStepper @shipment-created="shipmentCreated" :patient-profile="selectedPatientDetail"
            :is-patient-profile="true" :list-org-service-providers="listOrgServiceProviders"
            :inventory-item-input="inventoryItemInput" :ship-inventory-item="shipInventoryItem" />
        </v-card>
      </v-dialog>
    </v-card-title>
    <v-card-item>
      <v-container fluid class="pa-0">
        <v-table>
          <thead>
            <tr>
              <th v-for="column in columns" :key="column.key" scope="row" class="font-weight-bold">
                {{ column.label }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="loading">
              <td :colspan="columns.length">
                <div class="mt-8 loading-container">
                  <v-progress-circular indeterminate color="primary"></v-progress-circular>
                </div>
              </td>
            </tr>
            <tr v-else-if="!(patientDevicesList.length > 0)">
              <td :colspan="columns.length">
                <div class="noDataContainer mt-8">
                  <span class="text-h5 font-weight-bold">Nothing found</span>
                  <img src="../../../assets/empty.png" alt="No data found" class="noDataImage" />
                </div>
              </td>
            </tr>
            <template v-else v-for="(patientDevice, index) in patientDevicesList" :key="index">
              <tr :class="{ 'active-row': patientDevice.isActive }">
                <td class="text-left">{{ patientDevice.deviceId }}</td>
                <td class="text-left">{{ patientDevice.deviceType }}</td>
                <td class="text-left">{{ patientDevice.serviceProvider }}</td>
                <td class="text-left">{{ patientDevice.associationOn }}</td>
                <td class="text-left">
                  <v-badge v-if="patientDevice.isActive" color="success" content="Active" inline></v-badge>
                  <v-badge v-else color="error" content="Inactive" inline></v-badge>
                </td>
                <td class="text-left">
                  <v-icon @click="patientDevice.isExpanded = !patientDevice.isExpanded" :icon="patientDevice.isExpanded
                    ? 'mdi-chevron-up'
                    : 'mdi-chevron-down'
                    "></v-icon>
                </td>
              </tr>
              <transition name="v-table-nested">
                <tr v-if="patientDevice.isExpanded">
                  <td :colspan="columns.length">
                    <v-table class="v-table-nested" :class="{ active: patientDevice.isExpanded }">
                      <thead class="bg-surface">
                        <tr>
                          <th v-for="column in deviceListColumns" :key="column.key" scope="row"
                            class="font-weight-bold">
                            {{ column.label }}
                          </th>
                        </tr>
                      </thead>
          <tbody>
            <tr v-if="!(patientDevice.patientDevices.length > 0)">
              <td :colspan="deviceListColumns.length">
                <div class="noDataContainer mt-8">
                  <span class="text-h5 font-weight-bold">
                    Nothing found
                  </span>
                  <img src="../../../assets/empty.png" alt="No data found" class="noDataImage" />
                </div>
              </td>
            </tr>
            <tr v-else v-for="nestedDevice in patientDevice.patientDevices" :key="nestedDevice.patientDeviceId">
              <td class="text-left">
                {{ nestedDevice.shippingStatus }}
              </td>
              <td class="text-left">
                {{ nestedDevice.trackingCode }}
              </td>
              <td class="text-left">{{ nestedDevice.imei }}</td>
              <td class="text-left">
                {{ nestedDevice.ownByClinic }}
              </td>
            </tr>
          </tbody>
        </v-table>
        </td>
        </tr>
        </transition>
</template>
</tbody>
</v-table>
</v-container>
</v-card-item>
<v-snackbar color="error" class="text-white" v-model="showFetchPatientDevicesErrMsg" location="top right">
  {{ errorMessage }}
  <template v-slot:actions>
    <v-icon class="ml-3" @click="showFetchPatientDevicesErrMsg = false">mdi-close</v-icon>
  </template>
</v-snackbar>
<v-snackbar color="green" class="text-white" v-model="addDeviceSuccess" location="top right">
  Device was assigned successfully
  <template v-slot:actions>
    <v-icon class="ml-3" @click="addDeviceSuccess = false">mdi-close</v-icon>
  </template>
</v-snackbar>

<v-dialog v-model="showConfirmationPopup" persistent max-width="600px">
  <v-card min-height="200px">
    <v-card-title class="font-weight-bold bg-surface text-onSurfaceVar pl-5">Confirmation</v-card-title>
    <v-card-text class="text-h6">
      {{ templateParams.patientName }} is enrolled to RPM. Do you want to obtain their consent now to complete subscription?
    </v-card-text>
    <v-card-actions class="px-4 py-5">
      <v-row justify="end">
        <v-col sm="12" md="2" class="text-center">
          <v-btn color="primary" class="text-white" density="compact" variant="tonal" elevation="3" rounded="false"
            @click="showConfirmationPopup = false">
            No
          </v-btn>
        </v-col>
        <v-col sm="12" md="2" class="text-center" @click="() => { showTemplate = true, showConfirmationPopup = false }">
          <v-btn color="primary" class="text-white" variant="flat" type="submit" density="compact" elevation="3"
            rounded="false">
            Yes
          </v-btn>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</v-dialog>
<v-dialog fullscreen class="template-viewer-dialog" persistent v-model="showTemplate">
  <e-consent-template-viewer @close-template-viewer="showTemplate = false" :patient-profile="patientProfile"
    :econsent-template-id="programSubscribedData.eConsentTemplateId" :template-params="templateParams"
    :get-consent-upload-url="getPatientProgramConsentDependencies.getProgramEConsentUploadUrl"
    @reload-patient-program-consents="handleConsentSigned()"
    :sign-patient-program-consent="getPatientProgramConsentDependencies.signPatientProgramConsent"
    :selected-program-id="programSubscribedData.eConsentTemplateId"
    :data-loader="getPatientProgramConsentDependencies.getProgramEConsentTemplate"></e-consent-template-viewer>
</v-dialog>
</v-card>
</template>
<script setup lang="ts">
import { watch, PropType, reactive } from 'vue'
import { ref, onMounted } from 'vue'
import { includes, map, isEmpty } from 'lodash';
import {
  IDropdownInput,
  IExternalIds,
  ServiceProviderData,
  SourceDefinition,
  IOrgDevices,
  IAddSoureInput,
  ISoureData,
  ISource,
  ISourceList,
} from '@/interfaces/source.interface'
import { IPatientInfoExtended, IPatientProfileProps } from '@/interfaces/patient.interface'
import { IUser } from '@/interfaces/user.interface';
import { IAssignPatientInput, IInventoryDeviceInput, IInventoryItemData, IInventoryItemInput, IPatientProfileInventoryInput, IShipInventoryItemInput, ITenoviMeta } from '@/interfaces/InventoryItem.interface'
import { useEventBus } from '@vueuse/core';
import { deviceListEventBusKey } from '@/events/device-list-event.bus'
import { getShippingDetails } from '@/composables/DeviceUtility'
import CreatePatientStepper from '@/components/CreatePatientStepper.vue';
import hasAccess, { viewOrgInventoryRoles } from '@/composables/roleAccess'
import { IOrgServiceProvider } from '@/interfaces/IOrganization'
import { useUserStore } from '../../../store/modules/User';
import PatientDeviceAssignForm from './PatientDeviceAssignForm.vue'
import { IPatientDetailsDependencies, IPatientProgramConsentDependencies } from '@/interfaces/Service.interface'
import EConsentTemplateViewer from '../../econsent/EConsentTemplateViewer.vue';
import { EConsentAcknowledgement } from '@/enums/patient-program.enum'
import { IPatientProgram } from '@/interfaces/econsent.interface'
import moment from 'moment'
import { IEConsentTemplate, TemplateParams } from '@/interfaces/econsent-template.interface'
import { subscriptionEventBusKey } from '@/events/bus-keys/billing-program-subscription-event.bus-keys'

const showFetchPatientDevicesErrMsg = ref(false)
const errorMessage = ref('')

const props = defineProps({
  patientProfile: {
    type: Object as PropType<IPatientProfileProps>,
    required: true,
  },
  listOrgServiceProviders: {
    type: Function as PropType<(orgId: string) => Promise<IOrgServiceProvider[]>>,
    required: true,
  },

  listOrgDevices: {
    type: Function as PropType<
      (serviceProviderId: string) => Promise<IOrgDevices>
    >,
    required: true,
  },

  listExternalIds: {
    type: Function as PropType<
      (sourceDefinitionId: string, patientId: string) => Promise<IExternalIds>
    >,
    required: true,
  },

  listSourceDefinitionIds: {
    type: Function as PropType<
      (serviceProviderId: string) => Promise<SourceDefinition[]>
    >,
    required: true,
  },

  patientSourcesLoader: {
    type: Function as PropType<(patientId: string) => Promise<ISource[]>>,
    required: true,
  },

  savePatientSource: {
    type: Function as PropType<(source: IAddSoureInput) => Promise<ISoureData>>,
    required: true,
  },

  userHandler: {
    type: Function as PropType<() => IUser>,
    required: true,
  },
  listSourcesInput: {
    type: Object as PropType<ISourceList>,
    required: true,
  },
  assignInventoryItem: {
    type: Function as PropType<(itemId: string, input: IAssignPatientInput) => Promise<IInventoryDeviceInput>>,
    required: true,
  },
  inventoryItemInput: {
    type: Object as PropType<IInventoryItemInput>,
    required: true,
  },
  shipInventoryItem: {
    type: Function as PropType<(input: IShipInventoryItemInput) => Promise<IInventoryItemData>>,
    required: true,
  },
  patientDetail: {
    type: Function as PropType<(patientId: string[]) => Promise<IPatientInfoExtended>>,
    required: true,
  },
  getPatientProgramConsentDependencies: {
    type: Object as PropType<IPatientProgramConsentDependencies>,
    required: true,
  },
  getDetailsDependencies: {
    type: Object as PropType<IPatientDetailsDependencies>,
    required: true,
  },
})

const  { getPatientProgramConsentDependencies, patientProfile } = props;

const patientDevicesList = ref([] as any)
let addDeviceSuccess = ref(false)
const profilePatientId = ref('');
const serviceProviderOptions = ref([] as IDropdownInput[])
const loading = ref(false);
const dialog = ref(false);
const deviceListEventBus = useEventBus(deviceListEventBusKey);
const isShippingDialogOpen = ref(false);
const selectedPatientDetail = ref<IPatientInfoExtended>();
const showConfirmationPopup = ref(false);
const patientPrograms = ref([] as IPatientProgram[]);

const programSubscribedData = reactive({
  isProgramSubscribed: false,
  programStatus: '',
  eConsentTemplateId: '',
  programId: '',
});
const { patientInfo } = props.patientProfile;
const templateParams = ref({
  patientName: `${patientInfo?.firstName} ${patientInfo?.lastName}`,
  date: moment().format('MM/DD/YYYY')
} as TemplateParams);
const showTemplate = ref(false);

const columns = [
  { key: "deviceId", label: "Device ID" },
  { key: "deviceType", label: "Device Name" },
  { key: "serviceProvider", label: "Service Provider" },
  { key: 'associationOn', label: 'Associated On' },
  { key: "isActive", label: "Active" },
  { key: "action", label: "Actions" },
];

const deviceListColumns = [
  { key: "shippingStatus", label: "Shipping Status" },
  { key: "trackingCode", label: "Tracking Code" },
  { key: "imei", label: "IMEI" },
  { key: "ownByClinic ", label: "Own By Clinic" },
];

const getServiceProviders = async () => {
  try {
    loading.value = true;
    const orgId = useUserStore().$state.user.organizationId;
    const serviceProviders = await props.listOrgServiceProviders(orgId);
    serviceProviderOptions.value = map(serviceProviders, (providers) => ({
      title: providers.serviceProviderName!,
      value: providers.serviceProviderId!,
      shortCode: providers.shortCode,
    }));
    loading.value = false;
  } catch (err) {
    loading.value = false;
    errorMessage.value = "Error fetching serviceProviders";
  }
};

const openShippingDialog = () => {
  isShippingDialogOpen.value = true;
}

const shipmentCreated = () => {
  isShippingDialogOpen.value = false;
  listPatientDataSource(props.patientProfile.patientId);
}

const listPatientDataSource = async (patientId: string) => {
  const patientDevicesData = await props.patientSourcesLoader(patientId)
  patientDevicesList.value = map(patientDevicesData, (deviceData) => ({
    deviceId: deviceData.sourceMeta.deviceId,
    deviceType: deviceData.sourceMeta.name,
    serviceProvider: deviceData.serviceProvider!.serviceProviderName,
    isActive: deviceData.active,
    isExpanded: false,
    associationOn: moment(deviceData.sourceMeta.associatedOn).format("MM/DD/YY"),
    patientDevices: getShippingDetails(deviceData.sourceMeta as unknown as Record<string, unknown>, deviceData.serviceProvider!.shortCode!)
  }))
}

const inventoryAssignItem = async (PatientInput: IPatientProfileInventoryInput) => {
  try {
    loading.value = true;
    dialog.value = false;
    const { deviceId, patientId, patientIdentityAtSource } = PatientInput;
    const input = { patientId, patientIdentityAtSource };

    await props.assignInventoryItem(deviceId, input);

    const { isProgramSubscribed, programStatus, programId } = programSubscribedData;

    if (!isProgramSubscribed && (includes(['PENDING', 'SIGNED'], programStatus) || isEmpty(programStatus))) {
      await getPatientProgramConsentDependencies.subscribePrograms(patientProfile.patientId, [programId])
      useEventBus(subscriptionEventBusKey).emit();
      if(isEmpty(programStatus)){
        await loadPatientPrograms();
      }
    }

    showProgramConsentSignedConfirmation();

    loading.value = false;
    addDeviceSuccess.value = true;
    listPatientDataSource(props.patientProfile.patientId);
    deviceListEventBus.emit('getAssociatedDevices');
  }
  catch (error) {
    loading.value = false;
    const err = error as Error;
    errorMessage.value = err.message;
    showFetchPatientDevicesErrMsg.value = true;
  }
}

watch(
  () => props.patientProfile.patientId,
  (patientId) => {
    if (patientId) {
      listPatientDataSource(patientId)
    }
  },
)

const loadPatientPrograms = async () => {

  const getAllPrograms = await getPatientProgramConsentDependencies.getAllPrograms();

  const RPMProgram = getAllPrograms.find(p => p.shortCode === 'RPM');

  patientPrograms.value = (await getPatientProgramConsentDependencies.patientProgramConsents(props.patientProfile.patientId!)).map(
    (programConsent) => programConsent as IPatientProgram
  );

  const matchedData = patientPrograms.value.find((program) => program.programId === RPMProgram?.id);

  if (matchedData) {
    const { isSubscribed, consent, programId } = matchedData;

    programSubscribedData.isProgramSubscribed = isSubscribed;
    programSubscribedData.programStatus = consent?.status! || '';
    programSubscribedData.eConsentTemplateId = consent?.eConsentTemplateId! || '';
    programSubscribedData.programId = programId;

  }
}

const showProgramConsentSignedConfirmation = () => {

  const { isProgramSubscribed, programStatus } = programSubscribedData;

  if (isProgramSubscribed == null && programStatus === 'PENDING') {
    showConfirmationPopup.value = true;
  }
  else if (isProgramSubscribed && programStatus === 'PENDING') {
    showConfirmationPopup.value = true;
  }
  else {
    showConfirmationPopup.value = false;
  }

}

const handleConsentSigned = () => {
  showTemplate.value = false;
  useEventBus(subscriptionEventBusKey).emit();
}

onMounted(async () => {
  if (props.patientProfile.patientId) {
    profilePatientId.value = props.patientProfile.patientId;
    listPatientDataSource(props.patientProfile.patientId);
    getServiceProviders();
    selectedPatientDetail.value = await props.patientDetail([profilePatientId.value])!;
  }
});
</script>

<style lang="scss" scoped>
.paient-vitals-card {
  min-height: 400px;
}

.bp-reading-card {
  background-color: #3c30c4;
}

.vital-carousel {
  width: 100%;
}

.loading-container {
  min-height: 40px;
}

.grap-card {
  width: 90%;
  height: 200px;
}

.v-table-nested {
  border: 1px solid #ccc;
}

.action-container {
  display: flex;
  justify-content: flex-end;
}

.head-action-container {
  display: flex;
  justify-content: center;
}

.noDataContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.noDataImage {
  width: 15%;
  height: 15%;
}

.template-viewer-dialog {
  overflow-y: scroll;
}
</style>

<script lang="ts">
export default {
  name: 'PatientRPM',
}
</script>
