<template>
  <v-card flat class="w-100 h-100" color="surfContainerHighest">
    <v-toolbar density="compact" color="surface" class="mb-2" v-if="fullScreenMode">
      <v-toolbar-title class="text-primary"> Patient Profile </v-toolbar-title>
      <div class="task-details-actions">
        <v-btn icon color="primary" class="patient-monitor-toolbar-action" @click="handlePatientMonitorExit">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
    </v-toolbar>
    <v-skeleton-loader v-if="loadingPatientInfo" type="card" />
    <template v-else-if="isPatientInfoReady">
      <div class="pb-1  px-2">
        <patient-info :patient-profile="patientProfile" @request-patient-monitor-exit="handlePatientMonitorExit"
          :mark-insight-as-reviewed="getDependencies.markInsightAsReviewed" :compact-view="compactView"
          :add-time-log="getDependencies.createAddTimeLog" :current-activity="updatedActivity"
          :isDashboardView="isDashboardView" :get-patient-data-dependencies="getDependencies"
          :is-activity-timer-paused="isActivityTimerPaused" :activity-option="getDependencies.listActivities"
          :is-task-board-task-view="isTaskBoardTaskView" :add-note-handler="getDependencies.createNote"
          :patient-detail="getDependencies.patientDetail!"
          :list-org-service-providers="getDependencies.listOrgServiceProviders!" :patient-program-consents="getDependencies.patientProgramConsents" />
      </div>
      <div class="py-1 px-2">
        <patient-data :patient-profile="patientProfile" :compact-view="compactView"
          :get-patient-data-dependencies="getDependencies" :is-task-board-task-view="isTaskBoardTaskView"
          :vital-criteria-compact-view="vitalCriteriaCompactView" />
      </div>
    </template>
  </v-card>
</template>

<script setup lang="ts">
import { PropType, ref, defineEmits, onMounted, watch } from "vue";
import PatientInfo from "./PatientInfo.vue";
import { IPatientInfo, IPatientProfileProps } from "@/interfaces/patient.interface";
import PatientData from "./PatientData.vue";
import { PatientMonitorEmits } from "@/types/patient-monitor.types";
import router from "@/router";
import { ITaskActionEvent } from "@/interfaces/task.interface";
import { IPatientData } from "../../../interfaces/Service.interface";
import { taskActionFromTaskPopupEventBusKey } from "@/events/bus-keys/task-events.bus-keys";
import { useEventBus } from "@vueuse/core";
import { IOrgServiceProvider } from "@/interfaces/IOrganization";

const props = defineProps({
  patientProfile: {
    type: Object as PropType<IPatientProfileProps>,
    required: true,
  },
  getDependencies: {
    type: Object as PropType<IPatientData>,
    required: true,
  },
  routeOnExit: {
    type: String,
    required: false,
  },
  currentActivity: {
    type: Object as PropType<ITaskActionEvent>,
    required: false,
  },
  compactView: {
    type: Boolean,
    required: false,
    default: false,
  },
  isTaskBoardTaskView: {
    type: Boolean,
    default: false,
    required: false,
  },
  isDashboardView: {
    type: Boolean,
    default: false,
    required: false,
  },
  fullScreenMode: {
    type: Boolean,
    default: false,
  },
  vitalCriteriaCompactView: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const { patientProfile, getDependencies, routeOnExit } = props;

const patientMonitorEmit = defineEmits<PatientMonitorEmits>();
const updatedActivity = ref({} as ITaskActionEvent);

const isPatientInfoReady = ref(false);
const loadingPatientInfo = ref(false);
const taskActionFromTaskPopupEventBus = useEventBus(
  taskActionFromTaskPopupEventBusKey,
);
const isActivityTimerPaused = ref(false);

const handlePatientMonitorExit = () => {
  if (routeOnExit) {
    router.push({ name: routeOnExit });
  } else {
    patientMonitorEmit("requestPatientMonitorExit");
  }
};

watch(
  () => props.currentActivity,
  (newValue) => {
    updatedActivity.value = newValue!;
  },
);

onMounted(async () => {
  loadingPatientInfo.value = true;

  if (patientProfile.patientInfo) {
    isPatientInfoReady.value = true;
  }
  else if (patientProfile.patientInfoLoader) {
    patientProfile.patientInfo = {
      ...(await patientProfile.patientInfoLoader([patientProfile.patientId])),
    };
    isPatientInfoReady.value = true;
  }

  loadingPatientInfo.value = false;

  taskActionFromTaskPopupEventBus.on((e) => {
    updatedActivity.value = e;

    if (e && e.actionState === 'PAUSED') {
      isActivityTimerPaused.value = true;

    } else if (e && e.actionState === 'RESUMED') {
      isActivityTimerPaused.value = false;
    }
  });
});
</script>

<script lang="ts">
export default {
  name: "PatientMonitor",
};
</script>
<style scoped>
.loading-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.patient-monitor-toolbar-action {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
</style>
