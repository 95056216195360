import gql from "graphql-tag";

export const PATIENT_LINKED_USERS = gql`
  query pePatientLinkedUsers($patientId: String, $isActive: Boolean, $limit: Int, $offset: Int) {
    pePatientLinkedUsers(patientId: $patientId, isActive: $isActive, limit: $limit, offset: $offset ) {
      response {
      userId
      patientId
      firstName
      lastName
      relationship
      isActive
      email
      patientFirstName
      patientLastName
      createdTimeStamp
      updatedTimeStamp
    }
    count
    }
  }
`;

export const UPDATE_USER_PATIENT_LINK_STATUS = gql`
mutation updateUserPatientLinkActiveStatus($updateUserPatientLinkStatusInput: UpdateUserPatientLinkStatusInput!){
  updateUserPatientLinkActiveStatus(updateUserPatientLinkStatusInput: $updateUserPatientLinkStatusInput)
}
`
