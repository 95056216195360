import { updatePatientActiveStatus } from "@/services/patient-program-insight.service";
import gql from "graphql-tag";

export const PROGRAM_PATIENT_INSIGHTS = gql`
  query patientProgramInsights($program: Program!, $vital: Vital!) {
    patientProgramInsights(program: $program, vital: $vital) {
      totalPatientsInSystem
      totalPatientsInVital
      programs {
        RPM {
          vitals {
            BP {
              severity {
                critical
                moderate
                normal
              }
              compliance {
                compliant
                late
                veryLate
              }
              toReview
              toReviewSeverity {
                critical
                moderate
                normal
              }
            }
          }
        }
      }
    }
  }
`;

export const PATIENT_LISTS_BY_VITAL_SEVERITY = gql`
   query patientListByVitalsSeverity( $limit: Int, $offset: Int, $filter: PatientProgramVitalsFilter!, $sort: PatientProgramVitalsSortBy){
    patientListByVitalsSeverity(limit : $limit, offset : $offset, filter : $filter, sort :$sort){
      count
      results {
        patientId
        firstName
        lastName
        providerId
        providerName
        lastSync
        daysActive
        daysMissed
        daysLeft
        minutesSpent
        source
        isReviewed
        patientReadings {
          collectedTimestamp
          collectedTimeZone
          collectedBy
          reading {
            formatted {
              systolic
              diastolic
            }
          }
        }
      }
    }
  }
`;

export const PATIENTS_NEEDS_REVIEW = gql`
 query patientsNeedsReview( $limit: Int, $offset: Int, $filter: PatientProgramVitalsFilter!, $sort: PatientProgramVitalsSortBy){
  patientsNeedsReview(limit : $limit, offset : $offset, filter : $filter, sort :$sort){
      count
      results {
        patientId
        firstName
        lastName
        providerId
        providerName
        lastSync
        daysActive
        daysMissed
        daysLeft
        minutesSpent
        source
        isReviewed
        patientReadings {
          collectedTimestamp
          collectedTimeZone
          collectedBy
          reading {
            formatted {
              systolic
              diastolic
            }
          }
        }
      }
    }
  }
`

export const PATIENT_LISTS_BY_VITAL_COMPLIANCE = gql`
   query patientListByVitalsCompliance( $limit: Int, $offset: Int, $filter: PatientProgramVitalsFilter!, $sort: PatientProgramVitalsSortBy){
    patientListByVitalsCompliance(limit : $limit, offset : $offset, filter : $filter, sort :$sort){
      count
      results {
        patientId
        firstName
        lastName
        providerId
        providerName
        lastSync
        daysActive
        daysMissed
        daysLeft
        minutesSpent
        source
        isReviewed
        patientReadings {
          collectedTimestamp
          collectedTimeZone
          collectedBy
          reading {
            formatted {
              systolic
              diastolic
            }
          }
        }
      }
    }
  }
`;

export const MARK_INSIGHT_AS_VERIFIED = gql`
  mutation markInsightAsReviewed(
    $program: Program!
    $vital: Vital!
    $patientId: String!
    $isAcknowledged: Boolean!
  ) {
    markInsightAsReviewed(
      program: $program
      vital: $vital
      patientId: $patientId
      isAcknowledged: $isAcknowledged
    )
  }
`;

export const UPDATE_PATIENT_PROGRAM_INSIGHTS_STATUS  = gql`
  mutation updatePatientProgramInsightsStatus(
    $patientId: String!
    $isCurrent: Boolean!
  ) {
    updatePatientProgramInsightsStatus(
      patientId: $patientId
      isCurrent: $isCurrent
    )
  }
`;

export const UPDATE_PATIENT_ACTIVE_STATUS = gql`
  mutation updatePatientActiveStatus($input: updatePatientActiveStatus!) {
    updatePatientActiveStatus(input: $input)
  }
`;
