<template>
  <v-card flat color="surfContainer" class="py-2" elevation="2">
    <v-card-title>
      <v-toolbar color="surface" class="pa-2">
        <v-toolbar-title class="text-primary">
          <div class="d-flex align-center items-center text-primary">
            <v-icon size="large">mdi-crowd</v-icon>
            <div class="d-flex flex-wrap">
              <span class="ml-4">Patients</span>
              <span class="v-card-subtitle flex-1-1-100"> Manage patients in your organization</span>
            </div>
            <FilterListPatientChips class="ml-n10 pt-2" :filteredPatientChip="filteredPatientChip"
              @closePatientChip="closePatientChip" @close-patient-event-chip="closePatientEventChip"
              @close-patient-device-chip="closePatientsWithoutDeviceChip"
              @close-provider-name-chip="closeProviderNameChip" @close-d-o-b-chip="closeDOBChip" />
          </div>
        </v-toolbar-title>
        <FilterListPatient @apply-filter="patientFilter" :removed-patient-name-value="filteredPatientChip.patientName"
          :patient-without-device="filteredPatientChip.patientWithoutDevice"
          :removed-provider-name-value="filteredPatientChip.providerName" :removed-d-o-b-value="filteredPatientChip.dob"
          :removedPatientEventValue="patientEventFilterData" class="mx-2" />

        <v-tooltip location="bottom" v-if="areFiltersActive"> <template v-slot:activator="{ props }"> <v-btn
              v-if="areFiltersActive" color="primary" v-bind="props" :rounded="false" size="x-small" variant="tonal"
              elevation="3" @click="removeAllFilter" icon="mdi-filter-remove" class="mx-2" /> </template>Clear
          Filters</v-tooltip>

        <v-btn :loading="loading" variant="elevated" color="primary" rounded="false"
          @click="routeTo('CreatePatientStepper')" elevation="3" class="mx-2">
          <v-icon size="large">mdi-plus</v-icon>
          <span> {{ operationButtonName_1 }}</span>
        </v-btn>

      </v-toolbar>
    </v-card-title>
    <v-card-text>
      <v-skeleton-loader v-if="loading" color="surface" type="table"></v-skeleton-loader>
      <v-table v-else class="viewTable pa-2">
        <thead>
          <tr>
            <th v-for="column in columns" :key="column.key" class="text-left font-weight-bold fontweight" scope="row">
              <span v-if="column.key === 'lastName'" @click="handleSortOrderByName(column.key)"
                style="cursor: pointer;">
                {{ column.label }} <v-icon> {{ lastNameSortDirection }} </v-icon>
              </span>
              <span v-else-if="column.key === 'firstName'" @click="handleSortOrderByName(column.key)"
                style="cursor: pointer;">
                {{ column.label }} <v-icon> {{ firstNameSortDirection }} </v-icon>
              </span>
              <span v-else-if="column.key === 'provider'" @click="handleSortOrderByName(column.key)"
                style="cursor: pointer;">
                {{ column.label }} <v-icon> {{ providerNameSortDirection }} </v-icon></span>
              <span v-else-if="column.key == 'lastCall'" @click="handleSortOrder(PatientEventName.phone_call)"
                style="cursor: pointer;">{{ column.label }} <v-icon>{{ callSortDirection }}</v-icon></span>
              <span v-else-if="column.key == 'lastAppointment'" @click="handleSortOrder(PatientEventName.appointment)"
                style="cursor: pointer;">{{ column.label }} <v-icon>{{ appointmentSortDirection }}</v-icon></span>
              <span v-else>
                {{ column.label }}
              </span>
            </th>
            <th class="text-left font-weight-bold" scope="row">
              Active Status
            </th>
            <th class="text-left font-weight-bold" scope="row">
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="!patients.length">
            <td :colspan="columns.length + 1" class="text-center">
              No data available!
            </td>
          </tr>
          <tr v-else v-for="(patient, index) in patients" :key="patient.patientId">
            <td v-for="column in columns" :key="column.key" class="text-left">
              <div v-if="column.key === 'provider'">
                {{ getPrimaryProviderName(patient.providers) }}
              </div>
              <div v-else>
                {{ formatCellValue(patient, column) }}</div>
            </td>
            <td>
              <v-chip :color="patient.isActive ? 'success' : 'error'" rounded text-color="white">
                {{ patient.isActive ? "Active" : "Inactive" }}
              </v-chip>
            </td>
            <td class="text-left tableData d-flex justify-space-around">

              <div class="mx-2" v-if="hasAccess.viewPatientActions(viewOrgAdminRole)">
                <v-switch :value="true" density="compact" :color="patient.isDeceased ? 'red' : 'primary'" hide-details
                  @click="openConfirmationDialog(patient, index)" v-model="patient.isActive"
                  :disabled="patient.isDeceased">
                </v-switch>
                <v-tooltip activator="parent" location="bottom" :disabled="patient.isDeceased">
                  {{ patient.isActive ? 'Deactivate' : 'Activate' }}
                </v-tooltip>
              </div>

              <router-link :to="{
                name: 'patientProfileWithInfo',
                params: {
                  patientId: patient.patientId,
                  patient: encodePatientInfo(patient),
                }
              }">
                <v-btn id="viewPatient" :color="primaryColor" class="mx-2" rounded variant="tonal" size="small"
                  icon="mdi-account-eye" />
                <v-tooltip activator="parent" location="bottom">
                  Profile
                </v-tooltip>
              </router-link>
              <router-link :to="{
                name: 'EditPatient',
                params: {
                  patientId: patient.patientId,
                }
              }">
                <v-btn id="editPatient" :color="primaryColor" rounded class="mx-2" variant="tonal" size="small"
                  v-if="hasAccess.viewPatientActions(viewOrgAdminRole)" icon="mdi-pencil" />
                <v-tooltip activator="parent" location="bottom">
                  Edit
                </v-tooltip>
              </router-link>
            </td>
          </tr>
        </tbody>
      </v-table>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-pagination v-model="currentPage" rounded="circle" :length="totalPages"
        :total-visible="$vuetify.display.mdAndUp ? totalVisiblePageNo : $vuetify.display.sm ? 6 : 2"
        :density="'comfortable'" color="primary" @input="updateCurrentPage"
        class="my-4 px-5 custom-pagination"></v-pagination>
    </v-card-actions>
    <v-snackbar v-model="showSnackbar" color="success" location="top right" class="mt-16">{{ showSnackbarMessage }}
      <template v-slot:actions>
        <v-icon class="ml-3" @click="showSnackbar = false">mdi-close</v-icon>
      </template>
    </v-snackbar>
    <v-dialog v-model="showConfirmationPopup" persistent :width="activeStatus ? '550px' : '500px'">
      <v-card min-height="200px">
        <v-card-title class="font-weight-bold bg-surface pl-5">{{ activeStatus ? 'Deactivate' : 'Activate' }}
          Patient</v-card-title>
        <v-card-text class="text-h6">
          Are you sure you want to {{ activeStatus ? 'deactivate' : 'activate' }} the patient <v-label
            class="text-h6 font-weight-bold text-primary-darken-1" color="primary">{{ patientName }}</v-label>?
          <div v-if="activeStatus" class="mt-2 d-flex align-start">
            <v-icon color="warning" class="mr-2 mt-1">mdi-alert</v-icon>
            <span class="text-body-2">
              Upon deactivation, the patient will be unsubscribed from all the care programs and the currently assigned
              device(s) will be unassigned.
            </span>
          </div>
        </v-card-text>
        <v-card-actions class="px-4 py-5">
          <v-row justify="end">
            <v-col sm="12" md="3" class="text-center">
              <v-btn color="primary" class="text-white" density="comfortable" @click="closeConfirmationDialog"
                variant="tonal" elevation="3" block rounded="false">
                Cancel
              </v-btn>
            </v-col>
            <v-col sm="12" md="3" class="text-center">
              <v-btn color="primary" class="text-white" elevation="3" variant="flat" type="submit" block
                density="comfortable" rounded="false" @click="togglePatientStatus">
                Continue
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script setup lang="ts">
import { ref, computed, onMounted, inject, Ref, reactive } from 'vue';
import router from '../router';
import { GET_ALL_PATIENTS } from '../graphql/resources/PatientRepository';
import { ApolloClient } from '@apollo/client';
import { filter, head, isNull, forEach } from 'lodash'
import { IPatientEventFilter, IPatientEventSort, IPatientFilterChip, IPatientInfo, IsortByName, Provider } from '../interfaces/patient.interface';
import { encodePatientInfo } from '../composables/patient/view-utility.composable';
import { watch } from 'vue';
import FilterListPatient from './FilterListPatient.vue';
import FilterListPatientChips from './FilterListPatientChips.vue'
import { Gender, GenderLabel } from '../enums/genderType';
import { patientCreated } from '@/services/common.service';
import { ShowSnackbar } from '@/enums/sanckbar-show.enum';
import { useUserStore } from '@/store/modules/User';
import { updatePatient } from '@/services/patient.service';
import { updatePatientActiveStatus, updatePatientProgramInsightsStatus } from '@/services/patient-program-insight.service'
import hasAccess, {
  viewOrgAdminRole
} from "../composables/roleAccess";
import moment from "moment";
import { SortOrder } from '@/enums/patientInsights.enum';
import { PatientEventName } from '@/enums/patient-event.enum';
import { useRoute } from 'vue-router';
import { patientsWithoutDevices } from '@/services/inventory.service';

const props = defineProps({
  primaryColor: {
    type: String,
    default: 'primary',
  },
  filterIcon: {
    type: String,
    default: 'mdi-filter',
  },
  operationButtonName_1: {
    type: String,
    default: 'Add Patient',
  },
  operationButtonName_2: {
    type: String,
    default: 'Import Bulk Patient Records',
  },
  filterButtonName: {
    type: String,
    default: 'Filters',
  },
  actionButton_1: {
    type: String,
    default: 'Edit',
  },
  actionButton_2: {
    type: String,
    default: 'View Patient',
  },
  breadCrumbsIcon: {
    type: String,
    default: 'mdi-chevron-right',
  },
});

const columns = [
  { key: 'lastName', label: 'Last Name' },
  { key: 'firstName', label: 'First Name' },
  { key: 'provider', label: 'Primary Provider' },
  { key: 'dob', label: 'DOB' },
  { key: 'gender', label: 'Gender' },
  { key: 'lastCall', label: 'Last Call' },
  { key: 'lastAppointment', label: 'Last Appointment' }
];

const patients = ref([] as any[]);
const patientIds = ref([] as string[])
const apolloClient = inject('apolloClient') as ApolloClient<any>;
const loading = ref(false);
const itemsPerPage = ref(Number(import.meta.env.VITE_TOTAL_VISIBLE_PAGE_NO) || 10);
const currentPage = ref(1);
const totalCount = ref(0);
const filteredPatientChip = ref({} as IPatientFilterChip);
const removedPatientName = ref("");
const showChips = ref(false);
const totalVisiblePageNo = import.meta.env.VITE_TOTAL_VISIBLE_PAGE_NO;
const showSnackbar = ref(false);
const showSnackbarMessage = ref('');
const { roles, userId } = useUserStore().$state.user
const userRoles = roles.map(role => { return role.role });
const orgId = useUserStore().$state.user.organizationId;
const showConfirmationPopup = ref(false);
const patientName = ref('');
const switchOriginalState = ref(false);
const patientIndexValue = ref(0);
const patientId = ref('');
const activeStatus = ref(false);
const callSortDirection = ref('mdi-arrow-up');
const appointmentSortDirection = ref('mdi-arrow-up');
const providerNameSortDirection = ref('mdi-arrow-up');
const providerNameSortOrder = ref<null | SortOrder>(null);
const lastNameSortDirection = ref('mdi-arrow-up');
const firstNameSortDirection = ref('mdi-arrow-up');
const lastNameSortOrder = ref<null | SortOrder>(null);
const firstNameSortOrder = ref<null | SortOrder>(null);
const patientEventFilterData = ref({
  eventName: '',
  startDate: '',
  endDate: '',
} as IPatientEventFilter);
const sortingData = reactive<IPatientEventSort>({
  eventName: null,
  sortOrder: SortOrder.Default,
});
const patientEventName = ref('' as PatientEventName);
const sortByName = ref<null | IsortByName>(null);
const route = useRoute();

const totalPages = computed(() => Math.ceil(totalCount.value / itemsPerPage.value));

const formatCellValue = (patient: any, column: any) => {
  const value = patient[column.key];
  return value !== undefined && value !== null ? value : 'N/A';
};

const updateCurrentPage = (page: number) => {
  currentPage.value = page;
};

const patientFilter = (filter: IPatientFilterChip) => {
  filteredPatientChip.value = filter;
  patientEventFilterData.value = {
    eventName: filter.eventName,
    startDate: filter.startDate,
    endDate: filter.endDate,
  }
  showChips.value = true;
  currentPage.value = 1;
  const sortedData = sortingData?.eventName ? sortingData : null;
  if (filter.patientWithoutDevice) {
    fetchPatientsWithoutDevices()
  } else {
    getPatients(sortedData);
  }
};

const getPrimaryProviderName = (providers: Provider[]): string => {
  const primaryProvider = head(filter(providers, (provider) => { return provider.isPrimaryProvider }));
  if (primaryProvider) {
    return `${primaryProvider.firstName} ${primaryProvider.lastName}`;
  }

  return 'N/A';
}

const closePatientChip = (closeChip: string) => {
  filteredPatientChip.value.patientName = closeChip;
  removedPatientName.value = closeChip;
  showChips.value = false;
  const sortedData = sortingData?.eventName ? sortingData : null;
  getPatients(sortedData);
};

const closePatientEventChip = (eventChip: IPatientEventFilter) => {
  patientEventFilterData.value = eventChip;
  const sortedData = sortingData?.eventName ? sortingData : null;
  getPatients(sortedData);
}

const closeProviderNameChip = (closeChip: string) => {
  filteredPatientChip.value.providerName = closeChip;
  showChips.value = false;
  const sortedData = sortingData?.eventName ? sortingData : null;
  getPatients(sortedData);
};

const closeDOBChip = (closeChip: string) => {
  filteredPatientChip.value.dob = closeChip;
  showChips.value = false;
  const sortedData = sortingData?.eventName ? sortingData : null;
  getPatients(sortedData);
};

const items = [
  {
    title: 'Dashboard',
    disabled: false,
    href: 'breadcrumbs_dashboard',
  },
  {
    title: 'Administration',
    disabled: false,
    href: 'breadcrumbs_link_1',
  },
  {
    title: 'Patients',
    disabled: true,
    href: 'breadcrumbs_link_2',
  },
];

const navigation = [...items];

const routeTo = (routeToName: string) => {
  router.push({
    name: routeToName,
  });
};

const handleSortOrder = (eventName: PatientEventName) => {
  sortByName.value = null;
  providerNameSortDirection.value = 'mdi-arrow-up';
  firstNameSortDirection.value = 'mdi-arrow-up';
  lastNameSortDirection.value = 'mdi-arrow-up';
  if (patientEventName.value !== eventName) {
    patientEventName.value = eventName;
    sortingData.sortOrder = SortOrder.Default;
  }

  const eventSortOrder = {
    [SortOrder.Default]: SortOrder.Asc,
    [SortOrder.Asc]: SortOrder.Desc,
    [SortOrder.Desc]: SortOrder.Default,
  };

  sortingData.sortOrder = eventSortOrder[sortingData.sortOrder] ?? SortOrder.Default;


  sortingData.eventName =
    sortingData.sortOrder === SortOrder.Default ? null : eventName;

  callSortDirection.value =
    sortingData.sortOrder === SortOrder.Desc &&
      patientEventName.value === PatientEventName.phone_call
      ? "mdi-arrow-down"
      : "mdi-arrow-up";

  appointmentSortDirection.value =
    sortingData.sortOrder === SortOrder.Desc &&
      patientEventName.value === PatientEventName.appointment
      ? "mdi-arrow-down"
      : "mdi-arrow-up";

  getPatients(sortingData);
};
const removeAllFilter = () => {
  patientEventFilterData.value = {
    eventName: "",
    startDate: "",
    endDate: "",
  }
  filteredPatientChip.value = {
    dob: "",
    patientName: "",
    providerName: "",
    patientWithoutDevice: false,
    ...patientEventFilterData.value
  }
  const sortedData = sortingData?.eventName ? sortingData : null;
  getPatients(sortedData);
}

const areFiltersActive = computed(() => {
  return !!filteredPatientChip.value.dob || !!filteredPatientChip.value.providerName || !!filteredPatientChip.value.patientName || !!patientEventFilterData.value.eventName || filteredPatientChip.value.patientWithoutDevice;
});

const getPatients = async (sortOrder?: IPatientEventSort | null) => {
  loading.value = true;
  await apolloClient.query({
    query: GET_ALL_PATIENTS,
    variables: {
      limit: itemsPerPage.value,
      offset: itemsPerPage.value * (currentPage.value - 1),
      patientIds: filteredPatientChip.value.patientWithoutDevice ? patientIds.value : null,
      filter: {
        patientName: filteredPatientChip.value.patientName,
        patientEventSort: sortOrder?.eventName ? sortOrder : null,
        patientEventFilter: patientEventFilterData.value.startDate !== '' ? { ...patientEventFilterData.value } : undefined,
        sortByName: sortByName.value,
        providerName: filteredPatientChip.value.providerName,
        dob: filteredPatientChip.value.dob,
      },
    },
    fetchPolicy: 'network-only',
  })
    .then((data) => {
      patients.value = data.data.listPatients.results;
      totalCount.value = data.data.listPatients.count;
      patients.value = patients.value.map((patient: IPatientInfo) => {
        return {
          ...patient,
          patientName: `${patient.firstName} ${patient.lastName}`,
          providers: patient.providers,
          gender: GenderLabel[patient.gender as Gender],
          lastCall: patient.lastCall ? moment(patient.lastCall).format('MM/DD/YYYY  hh:mmA') : 'N/A',
          lastAppointment: patient.lastAppointment ? moment(patient.lastAppointment).format('MM/DD/YYYY  hh:mmA') : 'N/A',
        }
      });
    })
    .catch((err: any) => {
      console.log('Error in fetching patient info', err);
    });
  loading.value = false;
};

const handleSortOrderByName = (column: string) => {

  if (isNull(sortByName.value)) {
    providerNameSortOrder.value = null;
    lastNameSortOrder.value = null;
    firstNameSortOrder.value = null;
  }
  sortingData.sortOrder = SortOrder.Default;
  sortingData.eventName = null;
  callSortDirection.value = 'mdi-arrow-up';
  appointmentSortDirection.value = 'mdi-arrow-up';
  const columnSortMappings: Record<string, { sortOrder: Ref<SortOrder | null>, sortDirection: Ref<string> }> = {
    lastName: {
      sortOrder: lastNameSortOrder,
      sortDirection: lastNameSortDirection,
    },
    firstName: {
      sortOrder: firstNameSortOrder,
      sortDirection: firstNameSortDirection,
    },
    provider: {
      sortOrder: providerNameSortOrder,
      sortDirection: providerNameSortDirection,
    }
  };

  forEach(columnSortMappings, (sort, key) => {
    if (key !== column && !isNull(sort.sortDirection.value)) {
      sort.sortDirection.value = 'mdi-arrow-up';
      sort.sortOrder.value = null;
    }
  });

  const { sortOrder, sortDirection } = columnSortMappings[column];

  sortOrder.value = isNull(sortOrder?.value)
    ? SortOrder.Asc
    : sortOrder.value === SortOrder.Asc
      ? SortOrder.Desc
      : null;

  sortDirection.value = isNull(sortOrder?.value)
    ? 'mdi-arrow-up'
    : sortOrder.value === SortOrder.Asc
      ? 'mdi-arrow-up'
      : 'mdi-arrow-down';

  sortByName.value = {
    providerName: providerNameSortOrder.value,
    patientFirstName: firstNameSortOrder.value,
    patientLastName: lastNameSortOrder.value,
  };

  getPatients();
};


const openConfirmationDialog = async (
  patient: IPatientInfo, index: number
) => {
  patientName.value = `${patient.firstName} ${patient.lastName}`
  showConfirmationPopup.value = true;
  switchOriginalState.value = patient.isActive
  patientIndexValue.value = index;
  patientId.value = patient.patientId;
  activeStatus.value = patient.isActive;
};

const closeConfirmationDialog = async () => {
  patients.value[patientIndexValue.value].isActive = switchOriginalState.value;
  showConfirmationPopup.value = false;
}

const togglePatientStatus = async () => {
  showConfirmationPopup.value = false;
  loading.value = true;


  updatePatientActiveStatus({ patientId: patientId.value, isActive: !activeStatus.value })
    .then(() => {
      showConfirmationPopup.value = false;
      showSnackbarMessage.value = !activeStatus.value
        ? 'Patient activated successfully'
        : 'Patient deactivated successfully';
      showSnackbar.value = true;
    })
    .catch((error) => {
      console.error(error);
    })
    .finally(() => {
      loading.value = false;
    });

  if (activeStatus.value) {
    updatePatientProgramInsightsStatus(patientId.value, false);
  }

};

watch(currentPage, async () => {
  const sortedData = sortingData?.eventName ? sortingData : null;
  getPatients(sortedData);
})

const fetchPatientsWithoutDevices = async () => {

  filteredPatientChip.value.patientWithoutDevice = true
  loading.value = true;
  await patientsWithoutDevices().then((response) => {
    patientIds.value = response.records
  })

  getPatients();
}

const closePatientsWithoutDeviceChip = async () => {
  filteredPatientChip.value.patientWithoutDevice = false
  getPatients()
}

onMounted(() => {
  if (route.query.name === 'dashboard') {
    fetchPatientsWithoutDevices()
  }
  else {
    getPatients();
  }
  if (patientCreated.value === ShowSnackbar.PATIENT_CREATED) {
    showSnackbarMessage.value = 'Patient created successfully';
    showSnackbar.value = true;
    patientCreated.value = '';
  }
  if (patientCreated.value === ShowSnackbar.PATIENT_EDITED) {
    showSnackbarMessage.value = 'Patient record edited successfully';
    showSnackbar.value = true;
    patientCreated.value = '';
  }
})

defineExpose({
  columns,
  currentPage,
  totalPages,
  updateCurrentPage,
});

</script>

<style scoped>
.filter-box {
  display: flex;
  justify-content: flex-end;
}

.tableData {
  display: flex;
  flex-direction: row;
  align-items: center;
}
</style>
