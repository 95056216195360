import { Program } from "@/enums/patient-program.enum";
import { PATIENT_INTEGRATIONS } from "@/enums/patient.enum";
import { Vitals } from "@/enums/patientInsights.enum";
import { VITAL } from "@/enums/readings";
import {
  UPDATE_PATIENT_PROGRAM_INSIGHTS_STATUS,
  MARK_INSIGHT_AS_VERIFIED,
  PATIENTS_NEEDS_REVIEW,
  PROGRAM_PATIENT_INSIGHTS,
  UPDATE_PATIENT_ACTIVE_STATUS,
} from "@/graphql/resources/patientInshights/patientProgramInsightsRepository";
import {
  PatientProgramVitalsFilter,
  PatientProgramVitalsSortBy,
} from "@/interfaces/patientInsights.interface";
import { apolloClient } from "@/plugins/graphqlclient";

export const markInsightAsReviewed = async (
  patientId: string,
  program: Program,
  vital: Vitals,
  isAcknowledged: boolean
): Promise<boolean> => {
  const markInsightAsReviewed = await apolloClient.mutate({
    mutation: MARK_INSIGHT_AS_VERIFIED,
    variables: { patientId, program, vital, isAcknowledged },
  });
  return markInsightAsReviewed.data.markInsightAsReviewed;
};

export const getPatientReviewedStatus =  async (limit: number, offset: number, filter: PatientProgramVitalsFilter ,sortBy?: PatientProgramVitalsSortBy) => {
  const response   = await apolloClient.query({
    query: PATIENTS_NEEDS_REVIEW,
    variables: {
      limit: limit,
      offset: offset,
      filter: filter,
      sort : sortBy,
    },
    fetchPolicy: 'network-only'
  });
  return response.data.patientsNeedsReview;
}

export const getPatientInsightsOverview = async({program, vital}:{program:Program, vital: VITAL}) =>{
  const response = await apolloClient.query({
    query: PROGRAM_PATIENT_INSIGHTS,
    variables: {
      program: program,
      vital: vital,
    },
    fetchPolicy: "network-only",
  });
  return response.data.patientProgramInsights;
}

export const updatePatientProgramInsightsStatus = async (
  patientId: string,
  isCurrent: boolean
)=> {
  await apolloClient.mutate({
    mutation: UPDATE_PATIENT_PROGRAM_INSIGHTS_STATUS ,
    variables: { patientId, isCurrent },
  });
};

export const updatePatientActiveStatus = async ({
  patientId,
  isActive,
  isDeceased,
}: {
  patientId: string;
  isActive: boolean;
  isDeceased?: boolean;
}) => {
  await apolloClient.mutate({
    mutation: UPDATE_PATIENT_ACTIVE_STATUS,
    variables: { input: { patientId, isActive, isDeceased } },
  });
};
