<template>
  <v-card class="w-100 h-100 py-2 px-16" color="surfContainerHigh">
      <v-skeleton-loader v-if="loading" color="surface" type="table"></v-skeleton-loader>
      <v-card-item v-else>
        <div class="mb-5" style="float: right;">
          <v-btn :rounded="false" :loading="isLoading" elevation="3" @click="createPatientLinkCode" variant="flat">Generate patient Link Code</v-btn>
        </div>
        <v-data-table class="elevation-3" :headers="headers" :items="linkedUsers">
          <template v-slot:[`item.isActive`]="{ item }">
            <v-chip :color="item.isActive ? 'success' : 'error'" :rounded="false">
              {{ item.isActive ? "Active" : "Inactive" }}
            </v-chip>
          </template>
          <template v-slot:[`item.createdTimeStamp`]="{ item }">
            {{ moment(item.createdTimeStamp).format(`MM/DD/YY hh:mmA`) }}
          </template>
          <template v-slot:[`item.updatedTimeStamp`]="{ item }">
            {{ moment(item.updatedTimeStamp).format(`MM/DD/YY hh:mmA`) }}
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-menu location="right">
              <template v-slot:activator="{ props }">
                <v-btn icon="mdi-dots-vertical" :rounded="false" variant="text" v-bind="props"></v-btn>
              </template>
              <v-list density="compact">
                <v-list-item class="cursor-pointer text-primary" @click="updateStatus(item)">
                  {{ item.isActive ? "Deactivate" : "Activate" }}
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
      </v-card-item>
    <v-snackbar color="success" location="top right" v-model="isSuccess">{{ successMessage
      }}<template v-slot:actions>
        <v-icon class="ml-3" @click="isSuccess = false">mdi-close</v-icon>
      </template></v-snackbar>
    <v-snackbar color="error" location="top right" v-model="isError">{{ errorMessage
      }}<template v-slot:actions>
        <v-icon class="ml-3" @click="isError = false">mdi-close</v-icon>
      </template></v-snackbar>

    <v-dialog width="600" v-model="openOTPDialog" persistent>
      <v-card class="w-100">
        <v-card-title>
          <h5 class="text-h5 text-center text-onSurfaceVar font-weight-black w-100 py-2 bg-surfContainerHigh">Patient Link Code</h5>
        </v-card-title>
        <span style="display: flex; align-items: center; justify-content: center;" class=" py-10 text-h5 font-weight-bold text-primary">
          {{ patientLinkCode }}
          <v-icon @click="copyToClipboard" class="mx-5" color="primary" style="cursor: pointer;">mdi-content-copy</v-icon>
        </span>
        <v-card-actions>
          <v-btn :rounded="false" @click="openOTPDialog = false" variant="tonal" elevation="3" class="mx-3 mb-2" >Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>
<script setup lang="ts">
import { ref, PropType, onMounted } from "vue";
import { orderBy } from "lodash";
import moment from "moment";

import {
  IPatientLinkedUsersPE,
  IUpdateUserPatientLinkStatusInput,
} from "@/interfaces/PE/usePatientLink.interface";
import { IPatientDetailsDependencies } from "@/interfaces/Service.interface";
import { useEventBus } from "@vueuse/core";
import { listUserPatientLinkDataEventBusKey } from "@/events/bus-keys/PE/user-patient-link-event.bus-keys";

const props = defineProps({
  getDetailsDependencies: {
    type: Object as PropType<IPatientDetailsDependencies>,
    required: true,
  },

  patientId: {
    type: String,
    required: true,
  },
});

const { getDetailsDependencies, patientId } = props;

const headers = ref([
  { title: "First Name", key: "firstName" },
  { title: "Last Name", key: "lastName" },
  { title: "Email", key: "email" },
  { title: "Relationship with Patient", key: "relationship" },
  { title: "Created At", key: "createdTimeStamp" },
  { title: "Updated At", key: "updatedTimeStamp" },
  { title: "Link Status", key: "isActive" },
  { title: "Actions", key: "actions", sortable: false },
]);

const linkedUsers = ref<IPatientLinkedUsersPE[]>();
const listUserPatientLinkDataEventBus = useEventBus(listUserPatientLinkDataEventBusKey);

const loading = ref(false);
const isSuccess = ref(false);
const successMessage = ref("");
const isError = ref(false);
const errorMessage = ref("");
const openOTPDialog = ref(false);
const patientLinkCode = ref('');
const isLoading = ref(false);

const copyToClipboard = () => {
  navigator.clipboard.writeText(patientLinkCode.value);
  isSuccess.value = true;
  successMessage.value = 'Copied'
};

const getPatientLinkedUsers = async () => {
  loading.value = true;

  await getDetailsDependencies?.getPatientLinkedUsers!({patientId})
    .then((data) => {
      linkedUsers.value = data.response;
    })
    .catch((error) => {
      const err = error as Error;
      isError.value = true;
      errorMessage.value = err.message;
    })
    .finally(() => {
      loading.value = false;
    });
};

const updateStatus = async (userPatient: IPatientLinkedUsersPE) => {
  const updateUserPatientLinkStatusInput: IUpdateUserPatientLinkStatusInput = {
    patientId: userPatient.patientId,
    userId: userPatient.userId,
    isActive: !userPatient.isActive,
  };
  loading.value = true;
  await getDetailsDependencies
    .updateUserPatientLinkStatus(updateUserPatientLinkStatusInput)
    .then((data) => {
      if (!data) {
        isError.value = true;
        errorMessage.value = `Unable to ${updateUserPatientLinkStatusInput.isActive ? "activate" : "deactivate"} the user patient link.`;
      }

      isSuccess.value = true;
      successMessage.value = `User patient link ${updateUserPatientLinkStatusInput.isActive ? "activate" : "deactivate"} successfully.`;
      getPatientLinkedUsers();
    })
    .catch((error) => {
      const err = error as Error;
      isError.value = true;
      errorMessage.value = err.message;
    })
    .finally(() => {
      loading.value = false;
    });
};

const createPatientLinkCode  = async () => {
  isLoading.value = true;
   getDetailsDependencies.generatePatientLinkCode(patientId).then((response) => {
    patientLinkCode.value = response;
    openOTPDialog.value  =true;
   }).catch((error: Error) => {
     isError.value = true;
     errorMessage.value = error.message;
   }).finally(() => isLoading.value = false)
}

onMounted(() => {
  getPatientLinkedUsers();
  listUserPatientLinkDataEventBus.on(() => {
    getPatientLinkedUsers();
  })
});
</script>
